import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {Dimensions, SafeAreaView, View, Text, ScrollView} from 'react-native';
import {getMe, newHive} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {Formik} from 'formik';
const useNotify = require('../../helpers/use-notify').default;

const DatePicker = require('../../components/datePicker').default;
import SubPageTitle from '../../components/subpageTitle';

export default function NewHive() {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const {yardId, yardName} = route.params;
  const {success, error} = useNotify();

  async function onSaveHive({
    hiveCode,
    assessment,
    dateAssessed,
    notes,
    queen,
  }: {
    hiveCode: string;
    assessment?: string;
    queen?: string;
    dateAssessed: string;
    notes: string;
  }) {
    try {
      const meResult = await getMe();
      if (meResult instanceof Error) {
        throw meResult;
      }
      const result = await newHive({
        yardId: yardId,
        hiveCode,
        assessment,
        dateAssessed,
        notes,
        queen,
        userId: meResult.me.userId,
      });
      if (result instanceof Error) {
        error('Your new hive has not been saved!');
        throw result;
      } else {
        success('Your new hive has been saved!');
        navigation.pop();
      }
    } catch (e) {
      error('Error saving: ' + e);
    }
  }

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height}}>
      <Header />
      <SubPageTitle name={yardName} name2={'Add Hive'} />
      <View
        style={{
          height: Dimensions.get('window').height - 140,
        }}>
        <HiveView>
          <ScrollView
            style={{display: 'flex', alignSelf: 'center', width: '90%'}}>
            <Formik
              initialValues={{
                hiveCode: '',
                assessment: '',
                queen: '',
                dateAssessed: '',
                notes: '',
              }}
              onSubmit={values => {
                onSaveHive(values);
              }}>
              {({handleChange, handleBlur, handleSubmit, values}) => (
                <View style={{display: 'flex'}}>
                  <Text>Hive Number*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('hiveCode')}
                    onBlur={handleBlur('hiveCode')}
                    value={values.hiveCode}
                  />
                  <Text>Assessment Code</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('assessment')}
                    onBlur={handleBlur('assessment')}
                    value={values.assessment}
                  />
                  <Text>Date Assessed*</Text>
                  <DatePicker
                    onChangeText={handleChange('dateAssessed')}
                    date={values.dateAssessed}
                  />
                  <Text>Queen</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('queen')}
                    onBlur={handleBlur('queen')}
                    value={values.queen}
                  />
                  <Text>Notes</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('notes')}
                    onBlur={handleBlur('notes')}
                    value={values.notes}
                  />
                  <Button
                    style={{
                      width: '100%',
                      marginVertical: '0.5%',
                    }}
                    onPress={() => handleSubmit()}>
                    Save Hive
                  </Button>
                </View>
              )}
            </Formik>
          </ScrollView>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
