import React from "react";
import { View, SafeAreaView, ActivityIndicator } from "react-native";
import BigLoading from "../components/big-loading";

export default function Loading() {
  return (
    <SafeAreaView>
      <BigLoading />
    </SafeAreaView>
  );
}
