import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import {getMe, newTrip} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {User} from '../../types';
import {Formik} from 'formik';
const DatePicker = require('../../components/datePicker').default;
const useNotify = require('../../helpers/use-notify').default;
const Select = require('../../components/select').default;

import SubPageTitle from '../../components/subpageTitle';
import moment from 'moment';
import {numerRange} from '../../constants';

export default function NewTrip() {
  const [me, setMe] = useState<User>();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const {success, error} = useNotify();
  const large = Dimensions.get('window').width > 450;
  const scrollRef = useRef<ScrollView>(null);

  const {yardId, previousTrip, yardName} = route.params;

  async function onSaveTrip({
    visitDate,
    nextVisitDate,
    type,
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    boxesNeeded,
    pulled,
    nucs,
    notes,
  }: {
    visitDate: string;
    nextVisitDate: string;
    type: string;
    one: string;
    two: string;
    three: string;
    four: string;
    five: string;
    six: string;
    seven: string;
    eight: string;
    nine: string;
    boxesNeeded: string;
    pulled: string;
    nucs: string;
    notes?: string;
  }) {
    if (
      one.length == 0 ||
      two.length == 0 ||
      three.length == 0 ||
      four.length == 0 ||
      five.length == 0 ||
      six.length == 0 ||
      seven.length == 0 ||
      eight.length == 0 ||
      nine.length == 0 ||
      boxesNeeded.length == 0 ||
      pulled.length == 0 ||
      nucs.length == 0 ||
      type.length == 0 ||
      !me
    ) {
      error('Please fill out all required fields');
    } else {
      try {
        const result = await newTrip({
          yardId: +yardId,
          userId: me.userId,
          visitDate: new Date(visitDate.split('-').join('/')).toISOString(),
          nextVisitDate: new Date(
            nextVisitDate.split('-').join('/'),
          ).toISOString(),
          type,
          one: +one,
          two: +two,
          three: +three,
          four: +four,
          five: +five,
          six: +six,
          seven: +seven,
          eight: +eight,
          nine: +nine,
          boxesNeeded: +boxesNeeded,
          pulled: +pulled,
          nucs: +nucs,
          notes,
        });
        if (result instanceof Error) {
          error('Your new trip has not been saved!');
          throw error;
        } else {
          navigation.pop();
          success('Your new trip has been saved!');
        }
      } catch (e) {
        error('Error saving: ' + e);
      }
    }
  }

  useEffect(() => {
    async function fetchMe() {
      const result = await getMe();
      if (result instanceof Error) {
        throw result;
      }
      setMe(result.me);
    }
    fetchMe();
  }, []);

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height - 115}}>
      <Header />
      <SubPageTitle name={yardName} name2={'Add Trip'} />
      <HiveView>
        <KeyboardAvoidingView
          style={{flex: 1}}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          keyboardVerticalOffset={160}>
          <ScrollView ref={scrollRef}>
            <View style={{display: 'flex', alignSelf: 'center', width: '90%'}}>
              {me && (
                <Formik
                  initialValues={{
                    visitDate: moment().format('YYYY-MM-DD') ?? '',
                    nextVisitDate: moment()
                      .add(1, 'weeks')
                      .format('YYYY-MM-DD'),
                    type: (previousTrip && previousTrip.type) ?? 'normal',
                    one: (previousTrip && previousTrip.one) ?? '0',
                    two: (previousTrip && previousTrip.two) ?? '0',
                    three: (previousTrip && previousTrip.three) ?? '0',
                    four: (previousTrip && previousTrip.four) ?? '0',
                    five: (previousTrip && previousTrip.five) ?? '0',
                    six: (previousTrip && previousTrip.six) ?? '0',
                    seven: (previousTrip && previousTrip.seven) ?? '0',
                    eight: (previousTrip && previousTrip.eight) ?? '0',
                    nine: (previousTrip && previousTrip.nine) ?? '0',
                    boxesNeeded: '0',
                    pulled: '0',
                    nucs: (previousTrip && previousTrip.nucs) ?? '0',
                    notes: '',
                  }}
                  onSubmit={values => {
                    onSaveTrip(values);
                  }}>
                  {({
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => (
                    <View style={{display: 'flex'}}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Visit Date*</Text>
                        <DatePicker
                          disabled={me.role < 5}
                          onChangeText={handleChange('visitDate')}
                          date={values.visitDate}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Next Visit Date*</Text>
                        <DatePicker
                          disabled={me.role < 5}
                          onChangeText={handleChange('nextVisitDate')}
                          date={values.nextVisitDate}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Visit Type*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('type', option)
                            }
                            onBlur={handleBlur('type')}
                            selectedValue={values.type}
                            items={[
                              {label: 'Normal', value: 'normal'},
                              {label: 'Suspicious', value: 'suspicious'},
                            ]}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>One*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('one', option)
                            }
                            onBlur={handleBlur('one')}
                            selectedValue={values.one}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>

                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Two*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('two', option)
                            }
                            onBlur={handleBlur('two')}
                            selectedValue={values.two}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Three*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('three', option)
                            }
                            onBlur={handleBlur('three')}
                            selectedValue={values.three}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Four*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('four', option)
                            }
                            onBlur={handleBlur('four')}
                            selectedValue={values.four}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Five*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('five', option)
                            }
                            onBlur={handleBlur('five')}
                            selectedValue={values.five}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Six*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('six', option)
                            }
                            onBlur={handleBlur('six')}
                            selectedValue={values.six}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Seven*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('seven', option)
                            }
                            onBlur={handleBlur('seven')}
                            selectedValue={values.seven}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Eight*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('eight', option)
                            }
                            onBlur={handleBlur('eight')}
                            selectedValue={values.eight}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Nine*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('nine', option)
                            }
                            onBlur={handleBlur('nine')}
                            selectedValue={values.nine}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>Boxes Needed*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('boxesNeeded', option)
                            }
                            onBlur={handleBlur('boxesNeeded')}
                            selectedValue={values.boxesNeeded}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>pulled*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('pulled', option)
                            }
                            onBlur={handleBlur('pulled')}
                            selectedValue={values.pulled}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>nucs*</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            disabled={me.role < 5}
                            onValueChange={(option: string) =>
                              setFieldValue('nucs', option)
                            }
                            onBlur={handleBlur('nucs')}
                            selectedValue={values.nucs}
                            items={numerRange}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text style={{fontSize: 16}}>notes</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          multiline
                          numberOfLines={4}
                          onChangeText={handleChange('notes')}
                          onBlur={handleBlur('notes')}
                          value={values.notes}
                          maxLength={255}
                          onFocus={() => {
                            scrollRef?.current?.scrollToEnd();
                          }}
                        />
                      </View>
                      <Button
                        style={{
                          width: '100%',
                          marginVertical: '0.5%',
                        }}
                        onPress={() => handleSubmit()}>
                        Save Trip
                      </Button>
                    </View>
                  )}
                </Formik>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </HiveView>
    </SafeAreaView>
  );
}
