import {useNavigation} from '@react-navigation/native';
import React, {useState} from 'react';
import {Dimensions, SafeAreaView, View, Text} from 'react-native';
import {newCompany} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {CompanyBody} from '../../types';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import {Colors} from '../../constants';
const DatePicker = require('../../components/datePicker').default;
const Select = require('../../components/select').default;

export default function NewCompany() {
  const navigation = useNavigation<any>();
  const [showError, setShowError] = useState(false);

  async function onSaveCompany(companyData: CompanyBody) {
    const result = newCompany(companyData);
    if (result instanceof Error) {
      throw result;
    }
    navigation.pop();
  }
  return (
    <SafeAreaView>
      <Header />
      <SubPageTitle name={'Add Company'} />
      <View style={{height: Dimensions.get('window').height - 140}}>
        <HiveView>
          <View
            style={{
              height: Dimensions.get('window').height - 140,
              paddingVertical: 15,
            }}>
            <View style={{display: 'flex', alignSelf: 'center', width: '90%'}}>
              {showError && (
                <Text style={{color: 'red', fontSize: 18, textAlign: 'center'}}>
                  Please fill out all required fields
                </Text>
              )}
              <Formik
                initialValues={{
                  companyName: '',
                  address: '',
                  phone: '',
                  contact: '',
                  email: '',
                  adminEmail: '',
                  active: 0,
                  licensedYards: 1,
                  licensedUsers: 1,
                  trial: 0,
                  trialExpiry: '',
                  licenseExpiry: '',
                  paid: 0,
                  paidAmount: 0,
                }}
                onSubmit={values => {
                  onSaveCompany(values);
                }}>
                {({
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                }) => (
                  <View style={{display: 'flex', marginBottom: 40}}>
                    <View style={{marginBottom: 16}}>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('companyName')}
                          onBlur={handleBlur('companyName')}
                          value={values.companyName}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Address*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('address')}
                          onBlur={handleBlur('address')}
                          value={values.address}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Phone*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('phone')}
                          onBlur={handleBlur('phone')}
                          value={values.phone}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Contact Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('contact')}
                          onBlur={handleBlur('contact')}
                          value={values.contact}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Contact Email*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('email')}
                          onBlur={handleBlur('email')}
                          value={values.email}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Administrator Email*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('adminEmail')}
                          onBlur={handleBlur('adminEmail')}
                          value={values.adminEmail}
                        />
                      </View>
                      <Text>Active*</Text>
                      <Select
                        onValueChange={(option: string) =>
                          setFieldValue('active', option)
                        }
                        onBlur={handleBlur('active')}
                        selectedValue={values.active}
                        items={[
                          {label: 'no', value: 0},
                          {label: 'yes', value: 1},
                        ]}
                        placeholder={{}}
                      />
                      <View style={{marginVertical: 4}}>
                        <Text>Licensed Yards*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('licensedYards')}
                          onBlur={handleBlur('licensedYards')}
                          value={values.licensedYards.toString()}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Paid*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('paid')}
                          onBlur={handleBlur('paid')}
                          value={values.paid.toString()}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Paid Amount*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('paidAmount')}
                          onBlur={handleBlur('paidAmount')}
                          value={values.paidAmount.toString()}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>License Expiry*</Text>
                        <DatePicker
                          onChangeText={handleChange('licenseExpiry')}
                          date={values.licenseExpiry}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Trial Expiry*</Text>
                        <DatePicker
                          onChangeText={handleChange('trialExpiry')}
                          date={values.trialExpiry}
                        />
                      </View>
                    </View>

                    <Button
                      style={{
                        width: '100%',
                        marginVertical: '0.5%',
                      }}
                      onPress={() => handleSubmit()}>
                      Save Company
                    </Button>
                  </View>
                )}
              </Formik>
            </View>
          </View>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
