import React, {useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from 'react-native';
import Header from '../components/header';
import HiveView from '../components/hive-view';
import Icon from '../components/icons';
import Title from '../components/title';

function H1({content}: {content: React.ReactNode}) {
  return (
    <View style={{paddingVertical: 24}}>
      <Text style={{fontWeight: '800', fontSize: 32}}>{content}</Text>
    </View>
  );
}

function H2({content}: {content: React.ReactNode}) {
  return (
    <View style={{paddingVertical: 16}}>
      <Text style={{fontWeight: '700', fontSize: 24}}>{content}</Text>
    </View>
  );
}

function H3({content}: {content: React.ReactNode}) {
  return (
    <View style={{paddingVertical: 12}}>
      <Text style={{fontWeight: '600', fontSize: 18}}>{content}</Text>
    </View>
  );
}

export default function Info() {
  const [windowHeight, setWindowHeight] = useState<number>(
    Dimensions.get('window').height,
  );
  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View>
        <Header />
        <Title name="Help" />
      </View>
      <ScrollView
        style={{
          height: windowHeight - 90,
        }}>
        <HiveView>
          <View style={{paddingHorizontal: 16, paddingVertical: 8}}>
            <H1 content={<>1 Yards</>} />
            <Text>
              A YARD is a physical land location you keep your hives. Typical
              beekeepers may have between 10 and 50 hives per yard, every
              beekeeper is different. Upon logging in you will be shown a list
              of your current beeyards, and their last trip. Each yard will let
              you know the last visit date and when the next visit date is
              scheduled for your planning purposes. Click on any yard in this
              screen to view its information. From there you can then view the
              trips, and optionally (we don't use it) you can micromanage your
              individual hives. The yards will be sorted based on attention
              required (overdue, upcoming). When editing a Yard, we suggest
              making sure you set the “Capacity” of the Yards, the Name, the GPS
              co-ordinates, and the Directions. NOTE: for GPS coordinates to be
              automatically gathered by your device, you must enable GPS access
              in your mobile phone settings to the HiveTrak app. Alternatively
              you can paste or type in a GPS Lat/Long to map the yard.
            </Text>
            <H3 content={<>Overdue Visit</>} />
            <Text>
              A yard will be marked as overdue when the next visit date has
              passed and you have not visited the yard.
            </Text>
            <H3 content={<>Upcoming Visit</>} />
            <Text>
              A yard will be marked as upcoming when the next visit date is
              coming up in the next 2 days or the next visit is scheduled for
              today.
            </Text>
            <H2 content={<>1.1 GPS Coordinates</>} />
            <Text>
              When adding or editing a yard on your mobile device you will see
              an additional field named Current GPS Location. There will be a
              location icon that you can click which will start the geolocation
              service on your mobile device. It should prompt you for
              permission. If you allow this service to run it will attempt to
              pull your current GPS coordinates from the device. If successful,
              they will appear in the field. If the location picker is disabled,
              your phone privacy settings are blocking using the device to GPS
              location functions in the HiveTrak app.
            </Text>
            <H2 content={<>1.2 Trips</>} />
            <Text>
              A trip is a physical visit to a bee yard. Typically this is a
              truck and staff who are adding hives, moving hives, adding empty
              supers, pulling honey supers, cutting grass, doing splits,
              checking brood, administrating treatments, or performing other day
              to day tasks. HiveTrak is designed around tracking trips to yards
              and ensuring the yards not only have enough supers for nectar
              flow, but also get their honey pulled in a timely efficient order
              and get new empty supers added as soon as possible to continue
              optimal honey production during nectar flow. It is NOT intended to
              micromanage hives. The professional beekeeper will manage the bees
              using their skills supported by historical notes from the app, and
              manage trips and staff jobs using the app. IMPORTANT: In each trip
              you COUNT the hives by their total super height, between 1 super
              in a hive, and at the highest 9 supers in that hive (yes…we
              occasionally hit 10). INCLUDE the brood box(es) as supers in your
              counts. A hive that is only a single brood box is counted as a “1”
              tall hive. A double brood box hive with 3 honey supers is counted
              as a “5”, and so on. For each number in the trip, you select how
              many hives are that many supers tall using the scroll picker. We
              also track how many honey supers are needed, how many supers were
              pulled, and how many baby nucs (for us these are 4 or 5 frame
              hives) are in the yard. These total numbers are then used to model
              how many hives we have, how much room in yards we have for more
              hives, how many honey supers are in the field to pull, and how
              many baby nucs are in the field. These key metrics allow the user
              to plan and optimize their trips, trucks, supers, and extracting
              staff. You also can compare hive and honey super counts between
              trips to catch theft of hives or supers. It is highly suggested to
              have notepad and paper for truck staff to quickly write down the
              core information, and then during lunch or at down times enter the
              information into HiveTrak. Entering information while wearing
              equipment and having honey on your gloves or hands is not
              practical. Collecting the information is CRITICAL THOUGH and must
              be done entered by either an administrator or contributor user.
              Each yard will summarize a list of trips sorted by the Trip Visit
              Date in descending order (last visit at the top). Once a trip has
              been entered and saved it will show up in the list. If you click
              on the trip to update it, you will not be able to change the visit
              date - you can delete a trip if it is wrong and if you have
              sufficient permission for your user account. This prevents a user
              from creating one trip and updating it for every visit. To get the
              most benefit from HiveTrak, a new trip should be created every
              time you visit a yard. You may add as many trips to a yard as you
              like.
            </Text>
            <H2 content={<>1.3 Hives</>} />
            <Text>
              Each yard has the option to add and track individual hives. You
              may use this to enter all of your hives (micromanaging them) or to
              track special ones individually. You may add as many hives to a
              yard as you like. HiveTrak is NOT a hobbyist app. It is designed
              to quick efficient management of commercial operations, and
              assumes beekeepers know what they are doing. We are helping manage
              trips and operation planning to increase honey yields and reduce
              hive theft through honey super counts.
            </Text>
            <H1 content={<>2 Reports</>} />
            <H2 content={<>2.1 Yard Summary</>} />
            <Text>
              This is the main overview report. The Yard Summary report lists
              all yards and displays the last trip information for each yard.
              When a yard hasn't been visited at all it will be noted. This
              includes a grand total count of hives, honey supers, and baby
              nucs. Use this report for a quick planning tool on supering,
              pulling honey, doing splits, moving hives, and dispatching trucks
              to their daily trips.
            </Text>
            <H2 content={<>2.2 Yesterday's Visits Summary</>} />
            <Text>
              The Yesterday's Visits Summary lists all of the visits that
              occurred yesterday. This is based on the visit date.
            </Text>
            <H2 content={<>2.3 Today's Visits Summary</>} />
            <Text>
              The Today's Visits Summary lists all of the visits that occurred
              today. This is based on the visit date.
            </Text>
            <H2 content={<>2.4 Upcoming Visits Summary</>} />
            <Text>
              The Upcoming Visits Summary lists all of the scheduled visits for
              the next two days including today. This is based on the next visit
              date and includes a grand total of boxes needed for these trips.
            </Text>
            <H2 content={<>2.5 Overdue Visits Summary</>} />
            <Text>
              The Overdue Visits Summary lists all of the scheduled visits that
              have passed without a visit. This is based on the next visit date
              and includes a grand total of boxes needed for these trips.
            </Text>
            <H2 content={<>2.6 Notes Summary</>} />
            <Text>
              The Notes Summary lists the last 5 notes for each yard, sorted
              alphabetically. Use this report to see what has been happening in
              a yard over time. It is helpful to keep notes on problem hives,
              feeding, yard maintenance, queen activities, or other tasks which
              keep the yard producing maximum honey.
            </Text>
            <H2 content={<>2.7 Yard Location Map</>} />
            <Text>
              The Yard Location Map shows a Google map with markers for any of
              your yards that have a GPS Location entered. Use this for a visual
              overview of hive locations and remember that bees fly about two
              miles radius from their hive. Use two fingers on your mobile
              device to pinch and zoom in or out. Switch the view between
              satellite and map view using the toggle.
            </Text>
            <H1 content={<>3 Settings</>} />
            <Text>
              You will be able to access your settings using the{' '}
              <Icon.Settings /> icon.
            </Text>
            <H2 content={<>3.1 Profile</>} />
            <Text>
              Your profile consists of the information associated with your user
              that you can update.
            </Text>
            <H2 content={<>3.2 Password</>} />
            <Text>
              You can use the Password section to update your password. Once you
              update your password you will be logged out and required to log
              back into HiveTrak.
            </Text>
            <H2 content={<>3.3 Company</>} />
            <Text>
              Your company consists of the information associated with your
              HiveTrak account. Administrators have access to view and update
              company information.
            </Text>
            <H3 content={<>3.4 Users</>} />
            <Text>
              You have the option to create other user accounts for your
              company. You will be able to create as many users as you want.
              Having an account for each of your users is beneficial to your
              company. For yards, trips, and hives HiveTrak stores who updated
              an item last. If you have inconsistencies, you can track usernames
              using the Last Updated By field to find out where they are
              originating. Each user can log in to HiveTrak with their own
              account and depending on their level of access, interact with your
              data. There are three levels of access: Administrators,
              Contributors, Readers. The owner or main person for your company
              using the app should be an Administrator. Anyone who enters trips
              or data should be a Contributor. Planners and staff who do NOT
              modify data should be Readers.
            </Text>
            <H3 content={<>Administrators</>} />
            <Text>
              Administrators control the account. They have access to update the
              company information, add new users, edit existing users, add new
              yards, edit existing yards, add new trips, edit past trips, and
              delete entries. Deleted entries can be recovered by HiveTrak at
              your request for separate fees.
            </Text>
            <H3 content={<>Contributors</>} />
            <Text>
              Contributors can add new trips, edit today's trips, add new hives,
              and edit existing hives.
            </Text>
            <H3 content={<>Readers</>} />
            <Text>Readers can only view the data for a company.</Text>
            <H1 content={<>4 Mobile</>} />
            <Text>
              HiveTrak was designed with mobile in mind as well as working in
              any browser on a desktop. To make accessing the service easier,
              add the app to the home screen of your device with the following
              instructions, OR download the app from the app store.
            </Text>
            <H2 content={<>4.1 iOS</>} />
            <Text>
              The iPhone is one of the most common mobile devices. To add
              HiveTrak to your homescreen for quick and easy access in iOS:
            </Text>
            <FlatList
              style={{marginTop: 8}}
              data={[
                '1. Open http://app.hivetrak.ca in Safari.',
                '2. Locate the icon that looks like a square with an upward pointing arrow and click on it.',
                '3. Locate the Add To Home Screen button with a plus sign icon and click on it.',
                '4. Click on the Add button across the top.',
                '5. The HiveTrak icon will be added to your homescreen, most likely to the last page of apps or possibly as a new page.',
                '6. You can now access HiveTrak by simply clicking on this icon.',
              ]}
              renderItem={({item: text, index}) => (
                <Text style={{marginLeft: 8}}>
                  {index + 1}. {text}
                </Text>
              )}
              keyExtractor={index => index}
            />
            <H2 content={<>4.2 Other Devices</>} />
            <Text>
              Consult the manual of your device for instructions on how to add a
              website to your homescreen as an icon.
            </Text>
            <H1 content={<>5 Free Demo - One Yard.</>} />
            <Text>
              We keep it simple! HiveTrak is completely free for 1 full features
              yard. IF you decide you like HiveTrak and want to add more yards,
              it is $1/yard per month. You will need to enter payment
              information in order to add more than 1 yard. Example: 5 yards
              will automatically charge you $5 / month.
            </Text>
            <H1 content={<>6 Support</>} />
            <Text>
              Support is ONLY available by emailing sales@blueherongarden.ca at
              this time.
            </Text>
            <H1 content={<>7 Logout</>} />
            <Text>
              The <Icon.LogOut /> icon is used for logging out of HiveTrak.
            </Text>
          </View>
        </HiveView>
      </ScrollView>
    </SafeAreaView>
  );
}
