import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  ScrollView,
} from 'react-native';
import {deleteHive, getHive, getMe, updateHive} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {Hive, User} from '../../types';
import {Formik} from 'formik';
const useNotify = require('../../helpers/use-notify').default;

const DatePicker = require('../../components/datePicker').default;
import SubPageTitle from '../../components/subpageTitle';
import moment from 'moment';
import WarningModal from '../../components/warningModal';

export default function EditHive() {
  const [hive, setHive] = useState<Hive>();
  const [me, setMe] = useState<User>();
  const route = useRoute<any>();
  const {success, error} = useNotify();
  const navigation = useNavigation<any>();
  const [showWarning, setShowWarning] = useState(false);

  const {hiveId, yardName} = route.params;

  async function handleDelete() {
    try {
      const result = await deleteHive(hiveId);
      if (result instanceof Error) {
        throw result;
      } else {
        navigation.pop();
        success('Your trip has been deleted');
      }
    } catch (e) {
      error('Error deleting hive: ' + e);
    }
  }

  async function onSaveHive(updatedHive: Hive) {
    try {
      const result = await updateHive(hiveId, {
        ...updatedHive,
      });
      if (result instanceof Error) {
        error('Your hive has not been updated!');
        throw result;
      } else {
        success('Your hive has been updated!');
      }
    } catch (e) {
      error('Error saving: ' + e);
    }
  }

  async function fetchHive() {
    const result = await getHive(hiveId);
    if (result instanceof Error) {
      throw result;
    }

    setHive(result.hive);
  }

  async function fetchMe() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    }

    setMe(result.me);
  }

  useEffect(() => {
    fetchHive();
    fetchMe();
  }, []);

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height}}>
      <Header />
      <SubPageTitle
        name={hive?.hiveCode ?? ''}
        name2={yardName + '- Update Hive'}
      />
      <View
        style={{
          height: Dimensions.get('window').height - 140,
        }}>
        <HiveView>
          <WarningModal
            description="Are you sure? This will permentaly delete the hive"
            onCancel={() => setShowWarning(false)}
            visible={showWarning}>
            <Button
              type="danger"
              style={{
                width: '100%',
                marginVertical: '1%',
              }}
              onPress={() => handleDelete()}>
              Delete Hive
            </Button>
          </WarningModal>
          <ScrollView
            style={{display: 'flex', alignSelf: 'center', width: '90%'}}>
            {hive && me ? (
              <Formik
                initialValues={{
                  ...hive,
                  dateAssessed: moment
                    .utc(hive.dateAssessed)
                    .format('YYYY-MM-DD'),
                }}
                onSubmit={values => {
                  onSaveHive(values);
                }}>
                {({handleChange, handleBlur, handleSubmit, values}) => (
                  <View style={{display: 'flex'}}>
                    <Text>Hive Number*</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      onChangeText={handleChange('hiveCode')}
                      onBlur={handleBlur('hiveCode')}
                      value={values.hiveCode}
                      disabled={me.role < 5}
                    />
                    <Text>Assessment Code</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      onChangeText={handleChange('assessment')}
                      onBlur={handleBlur('assessment')}
                      value={values.assessment}
                      disabled={me.role < 5}
                    />
                    <Text>Date Assessed*</Text>
                    <DatePicker
                      onChangeText={handleChange('dateAssessed')}
                      date={values.dateAssessed}
                      disabled={me.role < 5}
                    />
                    <Text>Queen</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      onChangeText={handleChange('queen')}
                      onBlur={handleBlur('queen')}
                      value={values.queen}
                      disabled={me.role < 5}
                    />
                    <Text>Notes</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      onChangeText={handleChange('notes')}
                      onBlur={handleBlur('notes')}
                      value={values.notes}
                      disabled={me.role < 5}
                    />
                    <Text>Last updated by</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      value={values.userId.toString()}
                      disabled={me.role < 5}
                    />
                    {me.role > 1 && (
                      <View>
                        <Button
                          style={{
                            width: '100%',
                            marginVertical: '0.5%',
                          }}
                          onPress={() => handleSubmit()}>
                          Save Hive
                        </Button>
                        <Button
                          type="danger"
                          style={{
                            width: '100%',
                          }}
                          onPress={() => setShowWarning(true)}>
                          Delete Hive
                        </Button>
                      </View>
                    )}
                  </View>
                )}
              </Formik>
            ) : (
              <ActivityIndicator />
            )}
          </ScrollView>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
