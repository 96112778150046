import React from 'react';
import {
  TouchableOpacity,
  Text,
  TouchableOpacityProps,
  ActivityIndicator,
} from 'react-native';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends TouchableOpacityProps {
  loading?: boolean;
  type?: 'danger';
}

export default function Button(props: Props) {
  return props.type === 'danger' ? (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          borderColor: 'red',
          backgroundColor: 'transparent',
          borderWidth: 2,
          borderRadius: 10,
          paddingVertical: 8,
          paddingHorizontal: 4,
        },
        props as any,
      )}>
      <Text style={{fontSize: 18, color: 'red', textAlign: 'center'}}>
        {props.loading ? <ActivityIndicator color="white" /> : props.children}
      </Text>
    </TouchableOpacity>
  ) : (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          backgroundColor: 'black',
          borderRadius: 10,
          paddingVertical: 12,
          paddingHorizontal: 4,
        },
        props as any,
      )}>
      <Text
        style={{
          alignSelf: 'center',
          fontSize: 18,
          color: 'white',
          textAlign: 'center',
        }}>
        {props.loading ? <ActivityIndicator color="white" /> : props.children}
      </Text>
    </TouchableOpacity>
  );
}
