import React, {PropsWithChildren, useState} from 'react';
import {Dimensions, View, Text, TouchableOpacity} from 'react-native';
import Icon from '../components/icons';

export interface INotificationContext {
  visible: boolean;
  setVisible(b: boolean): unknown;
  content: React.ReactNode;
  setContent(c: React.ReactNode): unknown;
  type: 'success' | 'error';
  setType(t: 'success' | 'error'): unknown;
}

export const NotificationContext = React.createContext<INotificationContext>({
  visible: false,
  content: null,
  type: 'error',
  setVisible() {
    throw new Error('must be overridden');
  },
  setContent() {
    throw new Error('must be overridden');
  },
  setType() {
    throw new Error('must be overridden');
  },
});

export function NotificationContextProvider({children}: PropsWithChildren<{}>) {
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState<React.ReactNode>(null);
  const [type, setType] = useState<'success' | 'error'>('success');
  const width = Dimensions.get('window').width;
  const large = width > 450;

  return (
    <NotificationContext.Provider
      value={{visible, setVisible, content, setContent, type, setType}}>
      {children}
      {visible ? (
        <View
          style={{
            width: '100%',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            maxWidth: 0.9 * width,
          }}>
          <View
            style={{
              marginTop: 32,
              paddingVertical: 16,
              paddingHorizontal: 16,
              backgroundColor: type === 'success' ? '#e2fce9' : '#fff3f3',
              borderWidth: 2,
              borderLeftWidth: 4,
              borderColor: type === 'success' ? '#2aac53' : '#df1d2a',
              width: !large ? width * 0.85 : '',
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: large ? 'row' : 'column',
                alignItems: 'center',
              }}>
              <View style={{marginRight: 8}}>
                {type === 'success' ? (
                  <Icon.CheckCircle size={22} color="#2aac53" />
                ) : (
                  <Icon.XCircle size={22} color="#df1d2a" />
                )}
              </View>
              <View style={{marginRight: 8}}>
                <Text
                  style={{
                    color: 'black',
                    fontWeight: 'bold',
                    flex: 1,
                  }}>
                  {type === 'success' ? 'Success!' : 'Error!'}
                </Text>
              </View>
              <View style={{marginRight: 8}}>{content}</View>
              <View>
                <TouchableOpacity onPress={() => setVisible(false)}>
                  <Icon.X size={16} color="black" />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      ) : null}
    </NotificationContext.Provider>
  );
}
