import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  Platform,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Header from '../components/header';
import {getMe, getTrips, getYard} from '../api';
import Button from '../components/button';
import Card from '../components/card';
import {User, Yard, Yardcount} from '../types';
import {useNavigation, useRoute} from '@react-navigation/native';
import SubPageTitle from '../components/subpageTitle';
import HiveView from '../components/hive-view';
import moment from 'moment';
import {Colors} from '../constants';

export default function Trips() {
  const [trips, setTrips] = useState<Yardcount[]>();
  const [me, setMe] = useState<User>();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const [yard, setYard] = useState<Yard>();
  const windowHeight = Dimensions.get('window').height;
  const mobile = Platform.OS === 'ios' || Platform.OS === 'android';

  const {yardId} = route.params;

  async function fetchYard() {
    const result = await getYard(yardId);
    if (result instanceof Error) {
      throw result;
    }

    setYard(result.yard);
  }

  async function fetchMe() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    }

    setMe(result.me);
  }

  async function fetchTrips() {
    const result = await getTrips(yardId);

    if (result instanceof Error) {
      throw result;
    }
    const {yardcounts} = result;
    setTrips(
      yardcounts?.sort((a, b) =>
        moment(b.visitDate)
          .format()
          .localeCompare(moment.utc(a.visitDate).format()),
      ),
    );
  }
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchMe();
      fetchYard();
      fetchTrips();
    });

    return unsubscribe;
  }, [navigation, yardId]);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <Header />
      <SubPageTitle name={yard?.yardName ?? ''} name2={'Trips'} />
      <HiveView>
        <View style={{height: windowHeight * 0.875}}>
          <View
            style={{
              position: 'relative',
              flex: 1,
            }}>
            {me && me.role > 1 && (
              <TouchableOpacity
                style={{
                  backgroundColor: '#1167b1',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '6%',
                }}
                onPress={() =>
                  navigation.push('NewTrip', {
                    yardId: yardId,
                    previousTrip: trips?.sort((a, b) =>
                      moment(b.visitDate)
                        .format()
                        .localeCompare(moment(a.visitDate).format()),
                    )[0],
                    yardName: yard?.yardName,
                  })
                }>
                <Text style={{color: Colors.White, fontSize: 18}}>
                  New Trip
                </Text>
              </TouchableOpacity>
            )}
            {!trips ? (
              <ActivityIndicator />
            ) : trips.length > 0 ? (
              <View
                style={{
                  height: mobile ? windowHeight * 0.75 : windowHeight * 0.8,
                }}>
                <FlatList
                  data={trips}
                  renderItem={({item: {visitDate, yardcountId}, index}) => (
                    <TouchableOpacity
                      key={index}
                      style={{alignItems: 'center'}}
                      onPress={() =>
                        navigation.push('Trip', {
                          yardcountId: yardcountId,
                          yardName: yard?.yardName,
                        })
                      }>
                      <Card
                        key={index}
                        style={{
                          width: '95%',
                          marginVertical: 8,
                          marginHorizontal: 4,
                          paddingHorizontal: 16,
                          paddingVertical: 16,
                          backgroundColor: '#FFFFFA',
                        }}>
                        <View>
                          <Text style={{fontSize: 18}}>
                            {moment.utc(visitDate).format('LL')}
                          </Text>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item: {yardcountId: number}) =>
                    item.yardcountId.toString()
                  }
                />
              </View>
            ) : (
              <View
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  height: mobile ? windowHeight * 0.75 : windowHeight * 0.8,
                }}>
                <Text>
                  *There are no trips associated with this yard currently*
                </Text>
              </View>
            )}
          </View>
        </View>
      </HiveView>
    </SafeAreaView>
  );
}
