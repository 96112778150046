import {useEffect, useState} from 'react';

interface Cords {
  lat: number;
  lng: number;
}

export default function useLocation(): Cords | undefined {
  const [cords, setCords] = useState<Cords>();
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        setCords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
    );
  }, []);

  return cords;
}
