import {useNavigation} from '@react-navigation/native';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {getMe, getYards} from '../../api';
import Header from '../../components/header';
import SubPageTitle from '../../components/subpageTitle';
import YardData from '../../components/yardData';
import {SummaryMeta, YardMeta} from '../../types';

export default function UpcomingSummary() {
  const [summaries, setSummaries] = useState<SummaryMeta[]>([]);
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const windowHeight = Dimensions.get('window').height;

  useEffect(() => {
    const tempSummaries: SummaryMeta[] = [];
    async function fetchYards() {
      function calculateTripSummary(yard: YardMeta) {
        if (!yard.trips.length) {
          return {
            yardName: yard.yardName,
            LastVist: '0000-00-00',
            NextVisit: '0000-00-00',
            one: 0,
            two: 0,
            three: 0,
            four: 0,
            five: 0,
            six: 0,
            seven: 0,
            eight: 0,
            nine: 0,
            nucs: 0,
            pulled: 0,
            needed: 0,
            notes: '',
            total: 0,
          };
        }
        const mostRecentDate = new Date(
          Math.max(
            ...yard.trips.map(trip => new Date(trip.visitDate).getTime()),
          ),
        );
        const mostRecentTrip = yard.trips.find(
          trip =>
            new Date(trip.visitDate).getTime() === mostRecentDate.getTime(),
        );
        if (
          moment.utc().diff(mostRecentTrip?.nextVisitDate, 'days') > -2 &&
          moment.utc().diff(mostRecentTrip?.nextVisitDate, 'days') <= 0
        ) {
          tempSummaries.push({
            yardName: yard.yardName,
            LastVist: mostRecentTrip?.visitDate
              ? moment.utc(mostRecentTrip?.visitDate).format('LL')
              : '0000-00-00',
            NextVisit: mostRecentTrip?.nextVisitDate
              ? moment.utc(mostRecentTrip?.nextVisitDate).format('LL')
              : '0000-00-00',
            one: mostRecentTrip?.one ?? 0,
            two: mostRecentTrip?.two ?? 0,
            three: mostRecentTrip?.three ?? 0,
            four: mostRecentTrip?.four ?? 0,
            five: mostRecentTrip?.five ?? 0,
            six: mostRecentTrip?.six ?? 0,
            seven: mostRecentTrip?.seven ?? 0,
            eight: mostRecentTrip?.eight ?? 0,
            nine: mostRecentTrip?.nine ?? 0,
            nucs: mostRecentTrip?.nucs ?? 0,
            pulled: mostRecentTrip?.pulled ?? 0,
            needed: mostRecentTrip?.boxesNeeded ?? 0,
            notes: mostRecentTrip?.notes ?? '',
            total:
              (mostRecentTrip?.one ?? 0) +
              (mostRecentTrip?.two ?? 0) +
              (mostRecentTrip?.three ?? 0) +
              (mostRecentTrip?.four ?? 0) +
              (mostRecentTrip?.five ?? 0) +
              (mostRecentTrip?.six ?? 0) +
              (mostRecentTrip?.seven ?? 0) +
              (mostRecentTrip?.eight ?? 0) +
              (mostRecentTrip?.nine ?? 0),
          });
          return mostRecentTrip?.boxesNeeded ?? 0;
        } else {
          return 0;
        }
      }
      const meResult = await getMe();
      if (meResult instanceof Error) {
        throw meResult;
      }
      const result = await getYards(meResult.me.userId);
      if (result instanceof Error) {
        throw result;
      }
      const {yards} = result;
      let total = 0;
      for (let i = 0; i < yards?.length!; i++) {
        total = +calculateTripSummary(yards[i]);
      }
      setSummaries(tempSummaries);
      setGrandTotal(total);
    }
    fetchYards();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <SubPageTitle name="Upcoming Visits Summary" />
        <View
          style={{
            height: windowHeight - 140,
          }}>
          {!summaries ? (
            <ActivityIndicator />
          ) : summaries.length == 0 ? (
            <Text style={{textAlign: 'center'}}>
              *No upcoming visits in next two days*
            </Text>
          ) : (
            <View>
              <View>
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: '#1167b1',
                    padding: '1%',
                    backgroundColor: '#187bcd',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 20,
                      color: 'white',
                    }}>
                    {' '}
                    Report Grand Total
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 10,
                      color: 'white',
                    }}>
                    Upcoming activity report
                  </Text>
                </View>

                <Text
                  style={{
                    borderWidth: 1,
                    padding: '1%',
                    backgroundColor: '#d0efff',
                    borderColor: '#187bcd',
                    textAlign: 'center',
                  }}>
                  Boxes Needed: {grandTotal ? grandTotal : 0}
                </Text>
              </View>
              <FlatList
                data={summaries.sort((a, b) =>
                  a.yardName.localeCompare(b.yardName),
                )}
                renderItem={({item: yard, index}) => (
                  <View>
                    <YardData yardSummary={yard} />
                  </View>
                )}
              />
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
