import {useNavigation} from '@react-navigation/native';
import React, {useContext, useState} from 'react';
import {
  View,
  SafeAreaView,
  Image,
  ImageBackground,
  Dimensions,
  Text,
  TouchableOpacity,
  Alert,
  Platform,
} from 'react-native';
import {login} from '../api';
import Button from '../components/button';
import Input from '../components/input';
import AuthContext from '../context/auth.context';
const useNotify = require('../helpers/use-notify').default;

import storage from '../storage/storage.isomorphic';

export default function Login() {
  const width = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const large = width > 450;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const {setToken} = useContext(AuthContext);
  const {success, error} = useNotify();
  const mobile = Platform.OS === 'ios' || Platform.OS === 'android';

  const navigation = useNavigation<any>();

  async function onPressLogin() {
    setLoading(true);
    try {
      const result = await login(email, password);
      if (result instanceof Error) {
        throw result;
      }
      const {accessToken} = result;
      await storage.setItem('access_token', accessToken);
      setToken(accessToken);
    } catch (e) {
      console.log(e);
      error('Invalid credentials');
    } finally {
      setLoading(false);
    }
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: 'black',
        height: windowHeight,
      }}>
      <ImageBackground
        source={{
          uri: 'https://assets.rivercitytech.cloud/nodes/1/hivetrak/back.img',
        }}
        style={{
          height: '100%',
          flexDirection: 'column',
          alignItems: large ? 'flex-end' : 'center',
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '100%',
            width: large ? '60%' : '90%',
            maxWidth: 450,
            paddingVertical: 16,
            paddingHorizontal: 32,
            flex: 1,
          }}>
          <View style={{paddingTop: 100}}>
            <Image
              source={{
                uri: 'https://assets.rivercitytech.cloud/nodes/1/hivetrak/logo-small.img',
              }}
              style={{height: 150, width: 150}}
            />
          </View>
          <View style={{marginTop: 32, width: large ? '60%' : '90%'}}>
            <Input
              placeholder="Email"
              textContentType="none"
              onChange={e => setEmail(e.nativeEvent.text)}
            />
            <Input
              placeholder="Password"
              textContentType="password"
              secureTextEntry
              style={{marginTop: 8}}
              onChange={e => setPassword(e.nativeEvent.text)}
            />
            <Button
              style={{marginTop: 8}}
              loading={loading}
              onPress={onPressLogin}>
              Login
            </Button>
          </View>
          {!mobile && (
            <View style={{marginTop: 32}}>
              <Text style={{fontSize: 12}}>
                Dont have an account?{' '}
                <TouchableOpacity
                  onPress={() => navigation.navigate('Register')}>
                  <Text style={{color: 'orange'}}>Register.</Text>
                </TouchableOpacity>
              </Text>
            </View>
          )}
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
}
