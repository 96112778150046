import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {getMe, getYards} from '../../api';
import Header from '../../components/header';
import SubPageTitle from '../../components/subpageTitle';
import YardData from '../../components/yardData';
import {SummaryMeta, SummaryTotalMeta, YardMeta} from '../../types';

export default function YardSummary() {
  const [yards, setYards] = useState<YardMeta[]>();
  const [summaries, setSummaries] = useState<SummaryMeta[]>([]);
  const [grandTotal, setGrandTotal] = useState<SummaryTotalMeta>();
  const windowHeight = Dimensions.get('window').height;

  function calculateTripSummary(yard: YardMeta) {
    if (!yard.trips.length) {
      return {
        yardName: yard.yardName,
        LastVist: '0000-00-00',
        NextVisit: '0000-00-00',
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        five: 0,
        six: 0,
        seven: 0,
        eight: 0,
        nine: 0,
        nucs: 0,
        pulled: 0,
        needed: 0,
        notes: '',
        total: 0,
        honeyTotal: 0,
      };
    }
    const mostRecentDate = new Date(
      Math.max(...yard.trips.map(trip => new Date(trip.visitDate).getTime())),
    );
    const mostRecentTrip = yard.trips
      .filter(
        trip => new Date(trip.visitDate).getTime() === mostRecentDate.getTime(),
      )
      .sort((a, b) => b.yardcountId - a.yardcountId)[0];
    const total =
      (mostRecentTrip?.one ?? 0) +
      (mostRecentTrip?.two ?? 0) +
      (mostRecentTrip?.three ?? 0) +
      (mostRecentTrip?.four ?? 0) +
      (mostRecentTrip?.five ?? 0) +
      (mostRecentTrip?.six ?? 0) +
      (mostRecentTrip?.seven ?? 0) +
      (mostRecentTrip?.eight ?? 0) +
      (mostRecentTrip?.nine ?? 0);

    const honeyTotal =
      (mostRecentTrip?.three ?? 0) +
      (mostRecentTrip?.four ?? 0) * 2 +
      (mostRecentTrip?.five ?? 0) * 3 +
      (mostRecentTrip?.six ?? 0) * 4 +
      (mostRecentTrip?.seven ?? 0) * 5 +
      (mostRecentTrip?.eight ?? 0) * 6 +
      (mostRecentTrip?.nine ?? 0) * 7;

    const tempSummaries = summaries;
    tempSummaries.push({
      yardName: yard.yardName,
      LastVist: mostRecentTrip?.visitDate
        ? moment.utc(mostRecentTrip.visitDate).format('LL')
        : '0000-00-00',
      NextVisit: mostRecentTrip?.nextVisitDate
        ? moment.utc(mostRecentTrip.nextVisitDate).format('LL')
        : '0000-00-00',
      one: mostRecentTrip?.one ?? 0,
      two: mostRecentTrip?.two ?? 0,
      three: mostRecentTrip?.three ?? 0,
      four: mostRecentTrip?.four ?? 0,
      five: mostRecentTrip?.five ?? 0,
      six: mostRecentTrip?.six ?? 0,
      seven: mostRecentTrip?.seven ?? 0,
      eight: mostRecentTrip?.eight ?? 0,
      nine: mostRecentTrip?.nine ?? 0,
      nucs: mostRecentTrip?.nucs ?? 0,
      pulled: mostRecentTrip?.pulled ?? 0,
      needed: mostRecentTrip?.boxesNeeded ?? 0,
      notes: mostRecentTrip?.notes ?? '',
      total: total,
    });
    setSummaries(tempSummaries);
    return {
      one: mostRecentTrip?.one ?? 0,
      two: mostRecentTrip?.two ?? 0,
      three: mostRecentTrip?.three ?? 0,
      four: mostRecentTrip?.four ?? 0,
      five: mostRecentTrip?.five ?? 0,
      six: mostRecentTrip?.six ?? 0,
      seven: mostRecentTrip?.seven ?? 0,
      eight: mostRecentTrip?.eight ?? 0,
      nine: mostRecentTrip?.nine ?? 0,
      nucs: mostRecentTrip?.nucs ?? 0,
      total: total,
      honeyTotal: honeyTotal,
    };
  }

  async function fetchYards() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }
    const result = await getYards(meResult.me.userId);
    if (result instanceof Error) {
      throw result;
    }
    const {yards} = result;
    setYards(yards);

    let total = {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      six: 0,
      seven: 0,
      eight: 0,
      nine: 0,
      nucs: 0,
      total: 0,
      honeyTotal: 0,
    };
    for (let i = 0; i < yards?.length!; i++) {
      const tempTotal = calculateTripSummary(yards[i]);
      total = {
        one: total.one + tempTotal.one,
        two: total.two + tempTotal.two,
        three: total.three + tempTotal.three,
        four: total.four + tempTotal.four,
        five: total.five + tempTotal.five,
        six: total.six + tempTotal.six,
        seven: total.seven + tempTotal.seven,
        eight: total.eight + tempTotal.eight,
        nine: total.nine + tempTotal.nine,
        nucs: total.nucs + tempTotal.nucs,
        total: total.total + tempTotal.total,
        honeyTotal: total.honeyTotal + tempTotal.honeyTotal,
      };
    }
    setGrandTotal(total);
  }

  useEffect(() => {
    fetchYards();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <SubPageTitle name="Yard Summary" />
        {summaries && yards && grandTotal ? (
          <View
            style={{
              height: windowHeight - 140,
            }}>
            <View>
              <Text
                style={{
                  borderWidth: 1,
                  borderColor: '#1167b1',
                  padding: '1%',
                  backgroundColor: '#187bcd',
                  textAlign: 'center',
                  fontSize: 20,
                  color: 'white',
                }}>
                Report Grand Total
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    1
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.one ?? 0}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    2
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.two}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    3
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.three}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    4
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.four}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    5
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.five}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    6
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.six}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    7
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.seven}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    8
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.eight}
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '11.1%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                      backgroundColor: '#d0efff',
                    }}>
                    9
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      borderWidth: 1,
                      borderColor: '#187bcd',
                    }}>
                    {grandTotal.nine}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  borderWidth: 1,
                  padding: '1%',
                  backgroundColor: '#d0efff',
                  borderColor: '#187bcd',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    Total: {grandTotal.total}{' '}
                  </Text>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    Nucs: {grandTotal.nucs}
                  </Text>
                </View>

                <Text
                  style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}>
                  Honey Supers Total: {grandTotal.honeyTotal}
                </Text>
              </View>
            </View>
            <FlatList
              data={summaries.sort((a, b) =>
                a.yardName.localeCompare(b.yardName),
              )}
              renderItem={({item: yard, index}) => (
                <View>
                  <YardData yardSummary={yard} />
                </View>
              )}
            />
          </View>
        ) : (
          <ActivityIndicator />
        )}
      </View>
    </SafeAreaView>
  );
}
