import React from 'react';
import {Picker} from '@react-native-picker/picker';
import {Colors} from '../constants';
import {
  NativeSyntheticEvent,
  TargetedEvent,
  View,
  Text,
  Dimensions,
} from 'react-native';

interface Props {
  onValueChange: (value: string) => void;
  onBlur: (e: NativeSyntheticEvent<TargetedEvent>) => void;
  selectedValue: string;
  disabled?: boolean;
  items: {label: string; value: any}[];
  placeholder?: {label: string; value: any};
}

export default function Select(props: Props) {
  return !props.disabled ? (
    <Picker
      style={{
        width: '100%',
        borderColor: Colors.Border,
        marginVertical: 4,
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: 'white',
        paddingHorizontal: 8,
        paddingVertical: 8,
        fontSize: 18,
      }}
      onValueChange={props.onValueChange}
      onBlur={props.onBlur}
      selectedValue={props.selectedValue}>
      {props.items.map(item => (
        <Picker.Item label={item.label} value={item.value} />
      ))}
    </Picker>
  ) : (
    <View style={{marginVertical: 4}}>
      <Text
        style={{
          borderColor: Colors.Border,
          borderRadius: 5,
          borderWidth: 1,
          backgroundColor: '#d3d3d3',
          paddingHorizontal: 8,
          paddingVertical: 8,
          marginVertical: 4,
          fontSize: 18,
        }}>
        {
          props.items.filter(item => item.value === props.selectedValue)[0]
            .label
        }
      </Text>
    </View>
  );
}
