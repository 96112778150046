import {View, Text, Dimensions} from 'react-native';
import {Colors} from '../constants';
import React from 'react';
import {DatePickerProps} from '../types';

export default function DatePicker(props: DatePickerProps) {
  const large = Dimensions.get('window').width > 450;
  return !props.disabled ? (
    <input
      id="Date"
      type="date"
      className="form-control picker"
      name="Date"
      onChange={v => props.onChangeText(v.target.value)}
      value={props.date}
      style={{
        display: 'flex',
        width: large ? '88.5%' : '60%',
        borderColor: Colors.Border,
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: 'white',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 8,
        fontFamily: 'sans-serif',
        fontSize: 18,
      }}
    />
  ) : (
    <View style={{marginVertical: 4, width: large ? '88.5%' : '60%'}}>
      <Text
        style={{
          borderColor: Colors.Border,
          borderRadius: 5,
          borderWidth: 1,
          backgroundColor: '#d3d3d3',
          paddingHorizontal: 8,
          paddingVertical: 8,
          marginVertical: 4,
          fontSize: 18,
        }}>
        {props.date}
      </Text>
    </View>
  );
}
