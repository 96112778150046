import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  Platform,
  Linking,
} from 'react-native';
import {
  cancelSubscription,
  getCompany,
  getMe,
  getPaymentMethodPreview,
  getUpcomingInvoice,
} from '../../api';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import SubPageTitle from '../../components/subpageTitle';
import {Company, Invoice, PaymentMethodPreview} from '../../types';
import Loading from '../../components/loading';
import Button from '../../components/button';

import {Colors} from '../../constants';
import Card from '../../components/card';
import moment from 'moment';

export default function PaymentManagement() {
  const [invoice, setInvoice] = useState<Invoice>();
  const [paymentPreview, setPaymentPreview] = useState<PaymentMethodPreview>();
  const [company, setCompany] = useState<Company>();
  const windowHeight = Dimensions.get('window').height;
  const windowWidth = Dimensions.get('window').width;
  const large = windowWidth > 450;
  const navigation = useNavigation<any>();
  const mobile = Platform.OS === 'ios' || Platform.OS === 'android';

  async function onCancelSubscription() {
    const result = await cancelSubscription();
    if (result instanceof Error) {
      throw result;
    }
  }

  async function fetchData() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }
    const companyResult = await getCompany(meResult.me.companyId);

    if (companyResult instanceof Error) {
      throw companyResult;
    }
    setCompany(companyResult.company);
  }

  async function fetchInvoice() {
    const invoiceResult = await getUpcomingInvoice();
    if (invoiceResult instanceof Error) {
      throw invoiceResult;
    }
    setInvoice(invoiceResult.invoice);
  }

  async function fetchPreview() {
    const previewResult = await getPaymentMethodPreview();
    if (previewResult instanceof Error) {
      throw previewResult;
    }
    setPaymentPreview(previewResult.paymentMethodPreview);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
      fetchInvoice();
      fetchPreview();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{flex: 1}}>
      <View
        style={{
          height: windowHeight - 140,
        }}>
        <Header />
        <SubPageTitle name="License Management" />
        {company ? (
          <HiveView>
            <View
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Card
                style={{
                  width: '95%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 10,
                    }}>
                    <Text style={{fontSize: 18}}>Subscription</Text>
                  </View>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: large ? 'row' : 'column',
                        justifyContent: 'flex-start',
                      }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          paddingHorizontal: 5,
                        }}>
                        <Text style={{fontSize: 12, color: Colors.Gray}}>
                          Period Start
                        </Text>
                        <Text>
                          {invoice?.periodStart
                            ? moment(invoice?.periodStart * 1000).format(
                                'YYYY-MM-DD',
                              )
                            : 'Unavailable'}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          paddingHorizontal: 5,
                        }}>
                        <Text style={{fontSize: 12, color: Colors.Gray}}>
                          Period End
                        </Text>
                        <Text>
                          {invoice?.periodStart
                            ? moment(invoice?.periodEnd * 1000).format(
                                'YYYY-MM-DD',
                              )
                            : 'Unavailable'}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          paddingHorizontal: 5,
                        }}>
                        <Text style={{fontSize: 12, color: Colors.Gray}}>
                          Invoice Amount
                        </Text>
                        <Text>
                          {invoice?.amountDue
                            ? (invoice?.amountDue / 100).toLocaleString(
                                'en-US',
                                {style: 'currency', currency: 'USD'},
                              )
                            : 'Unavailable'}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        paddingHorizontal: 5,
                      }}>
                      <Text style={{fontSize: 12, color: Colors.Gray}}>
                        Card
                      </Text>

                      {paymentPreview ? (
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            paddingHorizontal: 5,
                          }}>
                          <Text>{paymentPreview.brand} </Text>
                          <Text>**** **** **** {paymentPreview.last4}</Text>
                        </View>
                      ) : (
                        <Text>Card Preview Unavailable</Text>
                      )}
                    </View>
                  </View>
                </View>
              </Card>

              {mobile ? (
                <Button
                  style={{width: '95%', marginVertical: 5}}
                  onPress={() =>
                    Linking.openURL(
                      'https://app.hivetrak.ca/settings/paymentManagement/update',
                    )
                  }>
                  Update Payment Method
                </Button>
              ) : (
                <Button
                  style={{width: '95%', marginVertical: 5}}
                  onPress={() => navigation.push('PaymentUpdate')}>
                  {paymentPreview
                    ? 'Change Payment Method'
                    : 'Add Payment Method'}
                </Button>
              )}
              {!mobile && (
                <Button
                  type="danger"
                  style={{width: '95%', marginVertical: 5}}
                  onPress={() => onCancelSubscription()}>
                  Cancel Subscription
                </Button>
              )}
            </View>
          </HiveView>
        ) : (
          <Loading />
        )}
      </View>
    </SafeAreaView>
  );
}
