import {useNavigation} from '@react-navigation/native';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {getMe, getYards} from '../../api';
import Header from '../../components/header';
import SubPageTitle from '../../components/subpageTitle';
import YardData from '../../components/yardData';
import {SummaryMeta, YardMeta} from '../../types';

export default function YesterdaySummary() {
  const [summaries, setSummaries] = useState<SummaryMeta[]>([]);
  const windowHeight = Dimensions.get('window').height;

  useEffect(() => {
    const tempSummaries: SummaryMeta[] = [];
    async function fetchYards() {
      function calculateTripSummary(yard: YardMeta) {
        if (!yard.trips.length) {
          return;
        }
        yard.trips.map(trip => {
          if (moment.utc().diff(trip?.visitDate, 'days') === 1) {
            tempSummaries.push({
              yardName: yard.yardName,
              LastVist: trip?.visitDate
                ? moment.utc(trip?.visitDate).format('LL')
                : '0000-00-00',
              NextVisit: trip?.nextVisitDate
                ? moment.utc(trip?.nextVisitDate).format('LL')
                : '0000-00-00',
              one: trip?.one ?? 0,
              two: trip?.two ?? 0,
              three: trip?.three ?? 0,
              four: trip?.four ?? 0,
              five: trip?.five ?? 0,
              six: trip?.six ?? 0,
              seven: trip?.seven ?? 0,
              eight: trip?.eight ?? 0,
              nine: trip?.nine ?? 0,
              nucs: trip?.nucs ?? 0,
              pulled: trip?.pulled ?? 0,
              needed: trip?.boxesNeeded ?? 0,
              notes: trip?.notes ?? '',
              total:
                (trip?.one ?? 0) +
                (trip?.two ?? 0) +
                (trip?.three ?? 0) +
                (trip?.four ?? 0) +
                (trip?.five ?? 0) +
                (trip?.six ?? 0) +
                (trip?.seven ?? 0) +
                (trip?.eight ?? 0) +
                (trip?.nine ?? 0),
            });
          }
        });
      }
      const meResult = await getMe();
      if (meResult instanceof Error) {
        throw meResult;
      }
      const result = await getYards(meResult.me.userId);
      if (result instanceof Error) {
        throw result;
      }
      const {yards} = result;
      for (let i = 0; i < yards?.length!; i++) {
        calculateTripSummary(yards[i]);
      }
      setSummaries(tempSummaries);
    }
    fetchYards();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <SubPageTitle name="Yesterday's Visit Summary" />
        <View
          style={{
            height: windowHeight - 140,
          }}>
          {!summaries ? (
            <ActivityIndicator />
          ) : summaries.length == 0 ? (
            <Text style={{textAlign: 'center'}}>
              *No visits were done yesterday*
            </Text>
          ) : (
            <View>
              <FlatList
                data={summaries.sort((a, b) =>
                  a.yardName.localeCompare(b.yardName),
                )}
                renderItem={({item: yard, index}) => (
                  <View>
                    <YardData yardSummary={yard} />
                  </View>
                )}
              />
            </View>
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
