import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import Button from '../components/button';
import Card from '../components/card';
import Clickable from '../components/clickable';
import Header from '../components/header';
import HiveView from '../components/hive-view';
import Icon from '../components/icons';
import Title from '../components/title';

export default function Reports() {
  const windowHeight = Dimensions.get('window').height;
  const navigation = useNavigation<any>();

  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={{height: windowHeight - 140}}>
        <Header />
        <Title name="Reports" />
        <HiveView>
          <View
            style={{
              display: 'flex',
              alignSelf: 'center',
              width: '95%',
            }}>
            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('YardSummary')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Yard Summary</Text>
                </View>
              </Card>
            </TouchableOpacity>
            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('YesterdaySummary')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>
                    Yesterday's Visit Summary
                  </Text>
                </View>
              </Card>
            </TouchableOpacity>

            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('TodaySummary')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Today's Visit Summary</Text>
                </View>
              </Card>
            </TouchableOpacity>

            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('UpcomingSummary')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Upcoming Visits Summary</Text>
                </View>
              </Card>
            </TouchableOpacity>

            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('OverdueSummary')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Overdue Visits Summary</Text>
                </View>
              </Card>
            </TouchableOpacity>

            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('NotesReport')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Notes Report</Text>
                </View>
              </Card>
            </TouchableOpacity>

            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('YardLocationMap')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Book size={14} />
                  <Text style={{paddingLeft: 10}}>Yard Locations Map</Text>
                </View>
              </Card>
            </TouchableOpacity>
          </View>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
