import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import React from 'react';
import AppContext from './app-context';
import config from './config';
import {NotificationContextProvider} from './context/notification.context';
const Router: React.FunctionComponent = require('./router').default;
import {ToastContainer} from 'react-toastify';

const stripePromise = loadStripe(config.STRIPE_PK);

export default function App() {
  return (
    <AppContext>
      <Elements stripe={stripePromise}>
        <NotificationContextProvider>
          <Router />
          <ToastContainer />
        </NotificationContextProvider>
      </Elements>
    </AppContext>
  );
}
