import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {getCompany, getUsers} from '../../api';
import Badge from '../../components/badge';
import Button from '../../components/button';
import Card from '../../components/card';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import SubPageTitle from '../../components/subpageTitle';
import {Company, User} from '../../types';

export default function Users() {
  const [users, setUsers] = useState<User[]>();
  const [company, setCompany] = useState<Company>();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const {companyId} = route.params;

  async function fetchUsers() {
    const result = await getUsers(companyId);
    if (result instanceof Error) {
      throw result;
    }

    setUsers(result.users);
  }

  async function fetchCompany() {
    const result = await getCompany(companyId);
    if (result instanceof Error) {
      throw result;
    }
    setCompany(result.company);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchCompany();
      fetchUsers();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height}}>
      <Header />
      <SubPageTitle name={company?.companyName ?? ''} name2={'Users'} />
      <View
        style={{
          height: Dimensions.get('window').height - 140,
        }}>
        <HiveView>
          <View
            style={{
              position: 'relative',
              flex: 1,
            }}>
            {!users ? (
              <ActivityIndicator />
            ) : users.length > 0 ? (
              <FlatList
                key={companyId}
                data={users}
                renderItem={({item: {username, userId, active}, index}) => (
                  <TouchableOpacity
                    key={index}
                    style={{alignItems: 'center'}}
                    onPress={() =>
                      navigation.navigate('User', {
                        userId: userId,
                      })
                    }>
                    <Card
                      key={index}
                      style={{
                        width: '95%',
                        marginVertical: 8,
                        marginHorizontal: 4,
                        paddingHorizontal: 16,
                        paddingVertical: 16,
                        backgroundColor: '#FFFFFA',
                        marginBottom:
                          index === users?.length! - 1 ? 92 : undefined,
                      }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginBottom: 10,
                        }}>
                        <Text style={{fontSize: 18}}>{username}</Text>
                        {active === 1 ? (
                          <Badge type="success">Active</Badge>
                        ) : (
                          <Badge type="danger">Inactive</Badge>
                        )}
                      </View>
                    </Card>
                  </TouchableOpacity>
                )}
                keyExtractor={(item: {userId: number}) =>
                  item.userId.toString()
                }
              />
            ) : (
              <View style={{display: 'flex', alignSelf: 'center'}}>
                <Text>
                  *There are no users associated with this company currently*
                </Text>
              </View>
            )}
            <Button
              style={{
                position: 'absolute',
                bottom: 32,
                width: '90%',
                marginLeft: '5%',
              }}
              onPress={() =>
                navigation.navigate('NewUser', {companyId: companyId})
              }>
              New User
            </Button>
          </View>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
