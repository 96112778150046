import {Formik} from 'formik';
import React, {useContext, useEffect, useLayoutEffect, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import {deleteCompany, getCompany, getMe, updateCompany} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import Input from '../../components/input';
import {Colors} from '../../constants';
import {Company, User} from '../../types';
import moment from 'moment';
import {useNavigation, useRoute} from '@react-navigation/native';
import SubPageTitle from '../../components/subpageTitle';
import HiveView from '../../components/hive-view';
import WarningModal from '../../components/warningModal';
const Select = require('../../components/select').default;
const useNotify = require('../../helpers/use-notify').default;
import storage from '../../storage/storage.isomorphic';
import AuthContext from '../../context/auth.context';

export default function UpdateCompany() {
  const [loading, setLoading] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const windowHeight = Dimensions.get('window').height;
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const {companyId} = route.params;
  const [me, setMe] = useState<User>();
  const [company, setCompany] = useState<Company>();
  const {success, error} = useNotify();
  const {setToken} = useContext(AuthContext);

  async function handleDelete() {
    setLoading(true);
    if (!companyId) {
      return;
    }
    const result = await deleteCompany(companyId);
    if (result instanceof Error) {
      error('Company has not been deleted!');
      throw result;
    } else if (me?.companyId === companyId) {
      await storage.clear();
      setToken(null);
    } else {
      success('Company has been deleted!');
      navigation.pop();
    }
    setLoading(false);
  }

  async function onPressUpdate(companyData: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
    contactName: string;
    contactEmail: string;
    adminEmail: string;
    bypassPayment?: number;
  }) {
    setLoading(true);
    if (company) {
      const result = await updateCompany(company?.companyId, {
        ...company,
        companyName: companyData.companyName,
        address: companyData.companyAddress,
        phone: companyData.companyPhone,
        contact: companyData.contactName,
        email: companyData.contactEmail,
        adminEmail: companyData.adminEmail,
        bypassPayment: companyData.bypassPayment
          ? +companyData.bypassPayment
          : company.bypassPayment,
      });

      if (result instanceof Error) {
        error('Company has not been updated!');
        throw result;
      } else {
        success('Company has been updated!');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    async function fetchCompanyData() {
      const companyResult = await getCompany(companyId);
      if (companyResult instanceof Error) {
        throw companyResult;
      }
      setCompany(companyResult.company);
    }
    async function fetchMe() {
      const meResult = await getMe();
      if (meResult instanceof Error) {
        throw meResult;
      }
      setMe(meResult.me);
    }
    fetchCompanyData();
    fetchMe();
  }, [companyId]);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <Header />
      <SubPageTitle
        name={company?.companyName ?? ''}
        name2={'Update Company'}
      />
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: windowHeight * 0.05,
        }}>
        <TouchableOpacity
          style={{
            backgroundColor: '#1167b1',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => navigation.push('Users', {companyId: companyId})}>
          <Text style={{color: Colors.White, fontSize: 16}}>Users</Text>
        </TouchableOpacity>
      </View>
      <ScrollView>
        <HiveView>
          <WarningModal
            description="Are you sure? This will permentaly delete the company and all users associated with the company"
            onCancel={() => setShowWarning(false)}
            visible={showWarning}>
            <Button
              type="danger"
              style={{
                width: '100%',
                marginVertical: '1%',
              }}
              onPress={() => handleDelete()}>
              Delete Company
            </Button>
          </WarningModal>
          <View
            style={{
              display: 'flex',
              alignSelf: 'center',
              width: '95%',
            }}>
            {company ? (
              <View>
                <Formik
                  key={company?.companyId}
                  initialValues={{
                    companyName: company?.companyName,
                    companyAddress: company?.address,
                    companyPhone: company?.phone,
                    contactName: company?.contact,
                    contactEmail: company?.email,
                    adminEmail: company?.adminEmail,
                    registrationDate: company?.registrationDate,
                    bypassPayment: company?.bypassPayment,
                  }}
                  onSubmit={values => {
                    onPressUpdate(values);
                  }}>
                  {({
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => (
                    <View style={{marginVertical: 16, display: 'flex'}}>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('companyName')}
                          onBlur={handleBlur('companyName')}
                          value={values.companyName}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Address*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('companyAddress')}
                          onBlur={handleBlur('companyAddress')}
                          value={values.companyAddress}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Company Phone*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('companyPhone')}
                          onBlur={handleBlur('companyPhone')}
                          value={values.companyPhone}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Contact Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('contactName')}
                          onBlur={handleBlur('contactName')}
                          value={values.contactName}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Contact Email*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('contactEmail')}
                          onBlur={handleBlur('contactEmail')}
                          value={values.contactEmail}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Administrator Email*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          onChangeText={handleChange('adminEmail')}
                          onBlur={handleBlur('adminEmail')}
                          value={values.adminEmail}
                        />
                      </View>
                      {me && me.role >= 15 && (
                        <View style={{marginVertical: 4}}>
                          <Text style={{fontWeight: '500'}}>
                            Bypass Payment
                          </Text>
                          <Select
                            onValueChange={(option: string) =>
                              setFieldValue('bypassPayment', option)
                            }
                            onBlur={handleBlur('bypassPayment')}
                            selectedValue={values.bypassPayment}
                            items={[
                              {label: 'no', value: 0},
                              {label: 'yes', value: 1},
                            ]}
                            placeholder={{}}
                          />
                        </View>
                      )}
                      <View style={{marginVertical: 4}}>
                        <Text style={{fontWeight: '500'}}>
                          Registration Date
                        </Text>
                        <Text
                          style={{
                            borderColor: Colors.Border,
                            borderRadius: 5,
                            borderWidth: 1,
                            backgroundColor: '#d3d3d3',
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            marginVertical: 4,
                            fontSize: 18,
                          }}>
                          {moment(values.registrationDate).format('LL')}
                        </Text>
                      </View>

                      {loading ? (
                        <ActivityIndicator />
                      ) : (
                        <View>
                          <Button
                            style={{
                              width: '100%',
                              marginVertical: 16,
                            }}
                            onPress={() => handleSubmit()}>
                            Update Company
                          </Button>
                          <TouchableOpacity
                            style={{
                              display: 'flex',
                              width: 'fit-content',
                              alignSelf: 'center',
                            }}
                            onPress={() => setShowWarning(true)}>
                            <Text
                              style={{
                                color: 'red',
                                textDecorationLine: 'underline',
                              }}>
                              Delete Company
                            </Text>
                          </TouchableOpacity>
                        </View>
                      )}
                    </View>
                  )}
                </Formik>
              </View>
            ) : (
              <ActivityIndicator />
            )}
          </View>
        </HiveView>
      </ScrollView>
    </SafeAreaView>
  );
}
