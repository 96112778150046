import GoogleMapReact, {Maps} from 'google-map-react';
import React, {useMemo} from 'react';
import {View, Text} from 'react-native';
import {Archive} from 'react-feather';
import {YardMeta} from '../types';
import BigLoading from './big-loading';
import config from '../config';

interface Props {
  yards: YardMeta[];
}

function YardPin({lat, lng, name}: {lat: number; lng: number; name: string}) {
  return (
    <View {...{lat, lng}} style={{display: 'flex', alignItems: 'center'}}>
      <Archive style={{color: '#9a7b4f', backgroundColor: 'yellow'}} />
      <Text style={{color: 'white', textAlign: 'center'}}>{name}</Text>
    </View>
  );
}

function getMapOptions(maps: Maps) {
  return {
    streetViewControl: false,
    scaleControl: true,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
          {
            visibility: 'off',
          },
        ],
      },
    ],
    gestureHandling: 'greedy',
    disableDoubleClickZoom: true,
    minZoom: 5,
    maxZoom: 18,

    mapTypeControl: true,
    mapTypeId: maps.MapTypeId.SATELLITE,
    mapTypeControlOptions: {
      style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
      position: maps.ControlPosition.BOTTOM_CENTER,
      mapTypeIds: [
        maps.MapTypeId.ROADMAP,
        maps.MapTypeId.SATELLITE,
        maps.MapTypeId.HYBRID,
      ],
    },

    zoomControl: true,
    clickableIcons: false,
  };
}

export default function GoogleMap(props: Props) {
  const defaultCenter = useMemo(() => {
    let avgLat = 0;
    let avgLng = 0;
    for (const {gpsLocation} of props.yards) {
      avgLat += +gpsLocation!.split(',')[0];
      avgLng += +gpsLocation!.split(',')[1];
    }
    return {
      lat: avgLat / props.yards.length || 0,
      lng: avgLng / props.yards.length || 0,
    };
  }, [props.yards]);

  if (props.yards.length === 0) {
    return <BigLoading />;
  }

  return (
    <GoogleMapReact
      bootstrapURLKeys={{key: config.GOOGLE_API_KEY}}
      defaultCenter={defaultCenter}
      zoom={12.5}
      options={getMapOptions}>
      {props.yards.map(({gpsLocation, yardName, yardId}) => (
        <YardPin
          key={yardId}
          lat={+gpsLocation!.split(',')[0]}
          lng={+gpsLocation!.split(',')[1]}
          name={yardName}
        />
      ))}
    </GoogleMapReact>
  );
}
