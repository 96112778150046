import React from 'react';
import {Dimensions, SafeAreaView, View} from 'react-native';
import Header from '../components/header';
import HiveView from '../components/hive-view';
import Title from '../components/title';
import HomeYards from './home/yards';

export default function Home() {
  const windowHeight = Dimensions.get('window').height;

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <Title name="Yards" />
        <View
          style={{
            height: windowHeight * 0.875,
          }}>
          <HiveView>
            <HomeYards />
          </HiveView>
        </View>
      </View>
    </SafeAreaView>
  );
}
