import {Storage} from '.';

const webStorage: Storage = {
  getItem: function (key: string): Promise<string | null> {
    return Promise.resolve(window.localStorage.getItem(key));
  },
  setItem: function (key: string, value: string): Promise<void> {
    return Promise.resolve(window.localStorage.setItem(key, value));
  },
  clear: function (): Promise<void> {
    return Promise.resolve(window.localStorage.clear());
  },
};
export default webStorage;
