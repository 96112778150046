import React, {useEffect, useState} from 'react';
import {
  ActivityIndicator,
  Dimensions,
  FlatList,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Header from '../components/header';
import {getHives, getMe, getTrips, getYard} from '../api';
import Button from '../components/button';
import Card from '../components/card';
import {Hive, User, Yard, Yardcount} from '../types';
import {useNavigation, useRoute} from '@react-navigation/native';
import SubPageTitle from '../components/subpageTitle';
import HiveView from '../components/hive-view';
import {Colors} from '../constants';
import moment from 'moment';

export default function Hives() {
  const [hives, setHives] = useState<Hive[]>();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const [yard, setYard] = useState<Yard>();
  const [me, setMe] = useState<User>();

  const {yardId} = route.params;

  async function fetchYard() {
    const result = await getYard(yardId);
    if (result instanceof Error) {
      throw result;
    }

    setYard(result.yard);
  }

  async function fetchMe() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    }

    setMe(result.me);
  }

  async function fetchHives() {
    const result = await getHives(yardId);
    if (result instanceof Error) {
      throw result;
    }
    const {hives} = result;
    setHives(hives);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchHives();
      fetchYard();
      fetchMe();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height}}>
      <Header />
      <SubPageTitle name={yard?.yardName ?? ''} name2={'Hives'} />
      <View
        style={{
          height: Dimensions.get('window').height - 140,
        }}>
        <HiveView>
          {me && me.role > 1 && (
            <TouchableOpacity
              style={{
                backgroundColor: '#1167b1',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                height: '6%',
              }}
              onPress={() =>
                navigation.navigate('NewHive', {
                  yardId: yardId,
                  yardName: yard?.yardName,
                })
              }>
              <Text style={{color: Colors.White, fontSize: 16}}>New Hive</Text>
            </TouchableOpacity>
          )}
          <View
            style={{
              position: 'relative',
              flex: 1,
            }}>
            {!hives ? (
              <ActivityIndicator />
            ) : hives.length > 0 ? (
              <FlatList
                data={hives}
                renderItem={({
                  item: {dateAssessed, hiveCode, hiveId},
                  index,
                }) => (
                  <TouchableOpacity
                    key={index}
                    style={{alignItems: 'center'}}
                    onPress={() =>
                      navigation.push('Hive', {
                        hiveId: hiveId,
                        yardName: yard?.yardName,
                      })
                    }>
                    <Card
                      key={index}
                      style={{
                        width: '95%',
                        marginVertical: 8,
                        marginHorizontal: 4,
                        paddingHorizontal: 16,
                        paddingVertical: 16,
                        backgroundColor: '#FFFFFA',
                      }}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 10,
                        }}>
                        <Text style={{fontSize: 18}}>{hiveCode}</Text>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                        }}>
                        <Text style={{fontSize: 12, color: Colors.Gray}}>
                          Date Assessed
                        </Text>
                        <Text>
                          {dateAssessed
                            ? moment.utc(dateAssessed).format('LL')
                            : 'Unknown Date'}
                        </Text>
                      </View>
                    </Card>
                  </TouchableOpacity>
                )}
                keyExtractor={(item: {hiveId: number}) =>
                  item.hiveId.toString()
                }
              />
            ) : (
              <View style={{display: 'flex', alignSelf: 'center'}}>
                <Text>
                  *There are no hives associated with this yard currently*
                </Text>
              </View>
            )}
          </View>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
