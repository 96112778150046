import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import {getMe} from '../api';
import Header from '../components/header';
import {User} from '../types';
import Title from '../components/title';
import Card from '../components/card';
import HiveView from '../components/hive-view';
import Icon from '../components/icons';

export default function Settings() {
  const windowHeight = Dimensions.get('window').height;
  const [me, setMe] = useState<User>();
  const navigation = useNavigation<any>();

  async function fetchMe() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }
    setMe(meResult.me);
  }

  useEffect(() => {
    fetchMe();
  }, []);

  return (
    <SafeAreaView style={{flex: 1}}>
      <View style={{height: windowHeight - 140}}>
        <Header />
        <Title name="Settings" />
        <HiveView>
          <View style={{width: '95%', display: 'flex', alignSelf: 'center'}}>
            <TouchableOpacity
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('Profile')}>
              <Card
                style={{
                  width: '100%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: '#FFFFFA',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <Icon.Settings size={14} />
                  <Text style={{paddingLeft: 10}}> Update Profile</Text>
                </View>
              </Card>
            </TouchableOpacity>
            {me && (
              <TouchableOpacity
                style={{alignItems: 'center'}}
                onPress={() => navigation.push('Password', {me: me})}>
                <Card
                  style={{
                    width: '100%',
                    marginVertical: 8,
                    marginHorizontal: 4,
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    backgroundColor: '#FFFFFA',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Icon.Settings size={14} />
                    <Text style={{paddingLeft: 10}}>Update Password</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
            {me && me.role > 5 && (
              <TouchableOpacity
                style={{alignItems: 'center'}}
                onPress={() =>
                  navigation.push('Company', {companyId: me.companyId})
                }>
                <Card
                  style={{
                    width: '100%',
                    marginVertical: 8,
                    marginHorizontal: 4,
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    backgroundColor: '#FFFFFA',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Icon.Settings size={14} />
                    <Text style={{paddingLeft: 10}}>Update Company</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
            {me && me.role === 15 && (
              <TouchableOpacity
                style={{alignItems: 'center'}}
                onPress={() => navigation.push('CompanyManagement')}>
                <Card
                  style={{
                    width: '100%',
                    marginVertical: 8,
                    marginHorizontal: 4,
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    backgroundColor: '#FFFFFA',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Icon.Settings size={14} />
                    <Text style={{paddingLeft: 10}}>Company Management</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
            {me && me.role > 5 && (
              <TouchableOpacity
                style={{alignItems: 'center'}}
                onPress={() => navigation.push('PaymentManagement')}>
                <Card
                  style={{
                    width: '100%',
                    marginVertical: 8,
                    marginHorizontal: 4,
                    paddingHorizontal: 16,
                    paddingVertical: 16,
                    backgroundColor: '#FFFFFA',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}>
                    <Icon.Settings size={14} />
                    <Text style={{paddingLeft: 10}}>Payment Management</Text>
                  </View>
                </Card>
              </TouchableOpacity>
            )}
          </View>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
