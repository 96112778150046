import {useNavigation} from '@react-navigation/native';
import {Formik} from 'formik';
import React, {useContext, useState} from 'react';
import {
  View,
  SafeAreaView,
  Image,
  ImageBackground,
  Dimensions,
  Text,
  TouchableOpacity,
  Alert,
  ActivityIndicator,
} from 'react-native';
import {register} from '../api';
import Button from '../components/button';
import Input from '../components/input';
import {RegisterData} from '../types';

export default function Register() {
  const width = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const [loading, setLoading] = useState(false);

  const navigation = useNavigation<any>();

  async function onPressRegister(registerData: RegisterData) {
    setLoading(true);
    try {
      const result = await register({
        email: registerData.email,
        username: registerData.username,
        password: registerData.password,
        companyName: registerData.companyName,
        companyPhone: registerData.companyPhone,
        companyAddress: registerData.companyAddress,
        companyEmail: registerData.companyEmail,
      });
      if (result instanceof Error) {
        throw result;
      }
      navigation.navigate('Login');
    } catch {
      Alert.alert('Invalid input');
    } finally {
      setLoading(false);
    }
  }

  return (
    <SafeAreaView
      style={{
        backgroundColor: 'black',
        height: windowHeight,
      }}>
      <ImageBackground
        source={{
          uri: 'https://assets.rivercitytech.cloud/nodes/1/hivetrak/back.img',
        }}
        style={{
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'white',
            height: '100%',
            width: '90%',
            maxWidth: 800,
            paddingVertical: 16,
            paddingHorizontal: 32,
            flex: 1,
          }}>
          <View>
            <Image
              source={{
                uri: 'https://assets.rivercitytech.cloud/nodes/1/hivetrak/logo-small.img',
              }}
              style={{height: 150, width: 150}}
            />
          </View>
          <View style={{marginTop: 32, width: '75%'}}>
            <Formik
              initialValues={{
                name: '',
                email: '',
                username: '',
                password: '',
                passwordConfirmation: '',
                companyName: '',
                companyPhone: '',
                companyAddress: '',
                companyEmail: '',
              }}
              onSubmit={values => {
                onPressRegister({
                  email: values.email,
                  username: values.username,
                  password: values.password,
                  companyAddress: values.companyAddress,
                  companyEmail: values.companyEmail,
                  companyName: values.companyName,
                  companyPhone: values.companyPhone,
                });
              }}>
              {({handleChange, handleBlur, handleSubmit, values}) => (
                <View style={{display: 'flex'}}>
                  <Text>Full Name*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('name')}
                    onBlur={handleBlur('name')}
                    value={values.name}
                  />
                  <Text>Email*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                  />
                  <Text>Username*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('username')}
                    onBlur={handleBlur('username')}
                    value={values.username}
                  />
                  <Text>Password*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('password')}
                    onBlur={handleBlur('password')}
                    value={values.password}
                  />
                  <Text>Password Confirmation*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('passwordConfirmation')}
                    onBlur={handleBlur('passwordConfirmation')}
                    value={values.passwordConfirmation}
                  />
                  <Text>Company Name*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('companyName')}
                    onBlur={handleBlur('companyName')}
                    value={values.companyName}
                  />
                  <Text>Company Address*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('companyAddress')}
                    onBlur={handleBlur('companyAddress')}
                    value={values.companyAddress}
                  />
                  <Text>Company Phone*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('companyPhone')}
                    onBlur={handleBlur('companyPhone')}
                    value={values.companyPhone}
                  />
                  <Text>Company Email*</Text>
                  <Input
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}
                    onChangeText={handleChange('companyEmail')}
                    onBlur={handleBlur('companyEmail')}
                    value={values.companyEmail}
                  />
                  <Button
                    style={{
                      width: '100%',
                      marginVertical: '0.5%',
                    }}
                    loading={loading}
                    onPress={() => handleSubmit()}>
                    Register
                  </Button>
                </View>
              )}
            </Formik>
          </View>
          <View style={{marginTop: 32}}>
            <Text style={{fontSize: 12}}>
              Already have an account?{' '}
              <TouchableOpacity onPress={() => navigation.navigate('Login')}>
                <Text style={{color: 'orange'}}>Login.</Text>
              </TouchableOpacity>
            </Text>
          </View>
        </View>
      </ImageBackground>
    </SafeAreaView>
  );
}
