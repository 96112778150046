export enum Colors {
  Primary = '#ee6e20',
  PrimaryLight = '#fd9251',
  PrimaryDark = '#d55127',
  OffWhite = '#d3e1e6',
  Border = '#999999',
  White = 'white',
  Background = '#f2f2f2',
  Inactive = '#adadad',
  Black = 'black',
  Gray = 'dimgray',
  ColouredCard = '#ffe5cf',
  Danger = 'red',
  Secondary = 'grey',
}

export enum YardStatus {
  OverdueVisit = 'Overdue Visit',
  UpcomingVisit = 'Upcoming Visit',
  NoStatus = '',
}

export const geoLocationRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const numerRange = Array(201)
  .fill(0)
  .map((e, i) => {
    return {label: `${i}`, value: i};
  });
