import {useNavigation} from '@react-navigation/native';
import React, {useState} from 'react';
import {View, Text, Dimensions} from 'react-native';

interface Props {
  name: string;
}

export default function Title({name}: Props) {
  const [windowHeight, setWindowHeight] = useState<number>(
    Dimensions.get('window').height,
  );

  return (
    <View
      style={{
        height: windowHeight * 0.05,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#373535',
        paddingHorizontal: 16,
      }}>
      <View>
        <Text style={{color: 'white', fontSize: 18}}>{name}</Text>
      </View>
      <View />
    </View>
  );
}
