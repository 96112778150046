import React from 'react';
import {View, Image, TouchableOpacity, Text, Dimensions} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Colors} from '../constants';
import Icon from './icons';

export default function Header() {
  const navigation = useNavigation<any>();
  const windowHeight = Dimensions.get('window').height;

  return (
    <View
      style={{
        height: windowHeight * 0.075,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'black',
        paddingHorizontal: 16,
        paddingVertical: 8,
      }}>
      <View>
        <TouchableOpacity onPress={() => navigation.navigate('Home')}>
          <Image
            source={{
              uri: 'https://assets.rivercitytech.cloud/nodes/1/hivetrak/logo-white.img',
            }}
            style={{width: 40, height: 40}}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <TouchableOpacity onPress={() => navigation.push('Menu')}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <Text
              style={{
                color: Colors.White,
                fontSize: 20,
                fontWeight: '500',
                paddingRight: 10,
              }}>
              Menu
            </Text>
            <Icon.Menu color="white" size={32} />
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
}
