import React from "react";
import { Text, View, ViewProps } from "react-native";
import composePropsWithDefaultStyles from "../helpers/compose-props-with-default-styles";

interface Props extends ViewProps {
  type: "success" | "danger" | "info" | "general";
  children: string;
}

export default function Badge(props: Props) {
  return (
    <View
      {...composePropsWithDefaultStyles(
        {
          backgroundColor:
            props.type === "info"
              ? "#adc6ff"
              : props.type === "danger"
              ? "#fee2e2"
              : props.type === "general"
              ? "#ffe5cf"
              : "#dcfce7",
          borderRadius: 50,
        },
        props as any
      )}
    >
      <Text
        style={{
          paddingHorizontal: 8,
          paddingVertical: 4,
          fontSize: 12,
          fontWeight: "600",
          color:
            props.type === "info"
              ? "#10239e"
              : props.type === "danger"
              ? "#b91c1c"
              : props.type === "general"
              ? "#c2410c"
              : "#166534",
          textAlign: "center",
        }}
      >
        {props.children}
      </Text>
    </View>
  );
}
