import {useNavigation} from '@react-navigation/native';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  TouchableOpacity,
  View,
  Text,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {getCompanies} from '../../api';
import Badge from '../../components/badge';
import Button from '../../components/button';
import Card from '../../components/card';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import SubPageTitle from '../../components/subpageTitle';
import {Colors} from '../../constants';
import {Company} from '../../types';
const mobile = Platform.OS === 'ios' || Platform.OS === 'android';

export default function CompanyManagement() {
  const windowHeight = Dimensions.get('window').height;
  const navigation = useNavigation<any>();
  const [companies, setCompanies] = useState<Company[]>();

  useEffect(() => {
    async function fetchCompanies() {
      const result = await getCompanies();

      if (result instanceof Error) {
        throw result;
      }

      setCompanies(result.companies);
    }
    const unsubscribe = navigation.addListener('focus', () => {
      fetchCompanies();
    });

    return unsubscribe;
  }, [navigation]);

  const keyExtractor = (item: any, index: string) => item.id.toString();

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <Header />
      <SubPageTitle name="Companies" />
      <HiveView>
        <View
          style={{
            height: windowHeight * 0.875,
          }}>
          <View
            style={{
              position: 'relative',
              flex: 1,
            }}>
            <View
              style={{
                height: mobile ? windowHeight * 0.75 : windowHeight * 0.8,
              }}>
              {companies ? (
                <FlatList
                  data={companies?.sort((a, b) =>
                    moment(b.registrationDate)
                      .format()
                      .localeCompare(moment(a.registrationDate).format()),
                  )}
                  renderItem={({
                    item: {
                      companyName,
                      registrationDate,
                      companyId,
                      active,
                      lastActive,
                    },
                    index,
                  }) => (
                    <TouchableOpacity
                      key={companyId}
                      style={{alignItems: 'center'}}
                      onPress={() =>
                        navigation.navigate('Company', {
                          companyId: companyId,
                        })
                      }>
                      <Card
                        style={{
                          width: '95%',
                          marginVertical: 8,
                          marginHorizontal: 4,
                          paddingHorizontal: 16,
                          paddingVertical: 16,
                          backgroundColor: '#FFFFFA',
                          marginBottom:
                            index === companies.length - 1 ? 92 : undefined,
                        }}>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 10,
                          }}>
                          <Text style={{fontSize: 18}}>{companyName}</Text>
                          {active === 1 ? (
                            <Badge type="success">Active</Badge>
                          ) : (
                            <Badge type="danger">Inactive</Badge>
                          )}
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}>
                            <Text style={{fontSize: 12, color: Colors.Gray}}>
                              Registration Date
                            </Text>
                            <Text>
                              {registrationDate
                                ? moment(registrationDate).format('LL')
                                : 'Unknown Date'}
                            </Text>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'flex-start',
                            }}>
                            <Text style={{fontSize: 12, color: Colors.Gray}}>
                              Last Active
                            </Text>
                            <Text>
                              {!lastActive
                                ? 'Unknown Date'
                                : moment(lastActive).isBefore(registrationDate)
                                ? moment(registrationDate).format('LL')
                                : moment(lastActive).format('LL')}
                            </Text>
                          </View>
                        </View>
                      </Card>
                    </TouchableOpacity>
                  )}
                  keyExtractor={(item: {companyId: number}) =>
                    item.companyId.toString()
                  }
                />
              ) : (
                <ActivityIndicator />
              )}
            </View>
            <Button
              style={{
                width: '90%',
                marginLeft: '5%',
              }}
              onPress={() => navigation.navigate('NewCompany')}>
              New Company
            </Button>
          </View>
        </View>
      </HiveView>
    </SafeAreaView>
  );
}
