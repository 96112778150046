import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  FlatList,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {getMe, getTrips, getYards} from '../../api';
import Header from '../../components/header';
import SubPageTitle from '../../components/subpageTitle';
import YardData from '../../components/yardData';
import {SummaryMeta, SummaryTotalMeta, YardMeta} from '../../types';

export default function NotesReport() {
  const [yards, setYards] = useState<YardMeta[]>();
  const [notes, setNotes] =
    useState<{yardName: string; trips: {date: string; note: string}[]}[]>();
  const windowHeight = Dimensions.get('window').height;

  async function fetchYards() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }
    const result = await getYards(meResult.me.userId);
    if (result instanceof Error) {
      throw result;
    }
    const {yards} = result;
    setYards(yards);
  }

  async function fetchNotes() {
    const processedYards = (await Promise.all(
      yards
        ?.filter(yard => yard !== undefined)
        .map(async yard => {
          const trips = await getTrips(yard.yardId);
          if (!(trips instanceof Error)) {
            const yardNotes = {
              yardName: yard.yardName,
              trips: trips.yardcounts
                ?.sort((a, b) =>
                  moment(b.visitDate)
                    .format()
                    .localeCompare(moment.utc(a.visitDate).format()),
                )
                .map(trip => {
                  return {note: trip.notes!, date: trip.visitDate};
                }),
            };
            return yardNotes;
          }
        })!,
    )) as {yardName: string; trips: {date: string; note: string}[]}[];
    setNotes(processedYards);
    console.log(processedYards);
  }

  useEffect(() => {
    fetchYards();
  }, []);

  useEffect(() => {
    fetchNotes();
  }, [yards]);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <SubPageTitle name="Notes Report" />
        <View
          style={{
            height: windowHeight - 140,
          }}>
          {notes && (
            <FlatList
              data={notes.sort((a, b) => a.yardName.localeCompare(b.yardName))}
              renderItem={({item: yard, index}) => (
                <View>
                  <View
                    style={{
                      display: 'flex',
                      alignSelf: 'center',
                      width: '100%',
                      flexDirection: 'column',
                      marginVertical: 1,
                    }}>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: '#1167b1',
                        padding: '1%',
                        backgroundColor: '#187bcd',
                      }}>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: 20,
                          color: 'white',
                        }}>
                        {yard.yardName}
                      </Text>
                    </View>
                    {yard.trips.slice(0, 5).map(trip => (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          borderWidth: 1,
                          borderColor: '#187bcd',
                          padding: '1%',
                          backgroundColor: '#d0efff',
                        }}>
                        <Text
                          style={{
                            textAlign: 'center',
                            fontSize: 15,
                            color: '#187bcd',
                          }}>
                          {moment.utc(trip.date).format('LL')}
                        </Text>
                        <Text
                          style={{
                            textAlign: 'center',
                            fontSize: 18,
                            color: '#187bcd',
                          }}>
                          {trip.note}
                        </Text>
                      </View>
                    ))}
                  </View>
                </View>
              )}
            />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
