import React, {useState} from 'react';
import {Modal, View, Text} from 'react-native';
import Button from './button';

interface Props {
  description?: string;
  children?: React.ReactNode;
  visible: boolean;
  onCancel?: () => void;
}

export default function WarningModal(props: Props) {
  const [showWarning, setShowWarning] = useState(false);
  return (
    <Modal animationType="slide" transparent={true} visible={props.visible}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 22,
        }}>
        <View
          style={{
            margin: 20,
            backgroundColor: 'white',
            borderRadius: 20,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
          }}>
          <Text
            style={{
              marginBottom: 15,
              textAlign: 'center',
              fontWeight: '700',
              color: 'red',
            }}>
            {props.description}
          </Text>
          {props.children}
          <Button
            style={{
              width: '100%',
              marginVertical: '1%',
            }}
            onPress={props.onCancel}>
            Cancel
          </Button>
        </View>
      </View>
    </Modal>
  );
}
