import React, {PropsWithChildren} from 'react';
import {useEffect, useState} from 'react';
import storage from '../storage/storage.isomorphic';

interface IAuthContext {
  loaded: boolean;
  token: string | null;
  setToken(token: string | null): void;
}

const AuthContext = React.createContext<IAuthContext>({
  loaded: false,
  token: null,
  setToken: () => {
    throw new Error('must be overriden');
  },
});
export default AuthContext;

interface Props {}

export function AuthContextProvider({children}: PropsWithChildren<Props>) {
  const [token, setToken] = useState<string | null>(null);
  const [loaded, setLoaded] = useState(false);

  async function fetchToken() {
    setToken(await storage.getItem('access_token'));
    setLoaded(true);
  }

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <AuthContext.Provider value={{token, loaded, setToken}}>
      {children}
    </AuthContext.Provider>
  );
}
