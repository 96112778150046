import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  Modal,
  Platform,
  findNodeHandle,
  ScrollView,
  KeyboardAvoidingView,
} from 'react-native';
import {deleteTrip, getMe, getTrip, updateTrip} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {User, Yardcount} from '../../types';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import moment from 'moment';
import WarningModal from '../../components/warningModal';
import {numerRange} from '../../constants';
const DatePicker = require('../../components/datePicker').default;
const useNotify = require('../../helpers/use-notify').default;
const Select = require('../../components/select').default;

export default function EditTrip() {
  const [trip, setTrip] = useState<Yardcount>();
  const [me, setMe] = useState<User>();
  const route = useRoute<any>();
  const {success, error} = useNotify();
  const {yardcountId, yardName} = route.params;
  const navigation = useNavigation<any>();
  const windowHeight = Dimensions.get('window').height;
  const [showWarning, setShowWarning] = useState(false);
  const large = Dimensions.get('window').width > 450;
  const scrollRef = useRef<ScrollView>(null);

  async function handleDelete() {
    const result = await deleteTrip(yardcountId);
    if (result instanceof Error) {
      throw result;
    } else {
      navigation.pop();
      success('Your trip has been deleted');
    }
  }

  async function onSaveTrip(updatedTrip: Yardcount) {
    try {
      const result = await updateTrip(yardcountId, {
        ...updatedTrip,
        one: +updatedTrip.one,
        two: +updatedTrip.two,
        three: +updatedTrip.three,
        four: +updatedTrip.four,
        five: +updatedTrip.five,
        six: +updatedTrip.six,
        seven: +updatedTrip.seven,
        eight: +updatedTrip.eight,
        nine: +updatedTrip.nine,
        boxesNeeded: +updatedTrip.boxesNeeded,
        pulled: +updatedTrip.pulled,
        nucs: +updatedTrip.nucs,
      });
      if (result instanceof Error) {
        error('Your trip has not been updated!');
        throw result;
      } else {
        success('Your trip has been updated!');
      }
    } catch (e) {
      error('Error updating: ' + e);
    }
  }

  async function fetchTrip() {
    const result = await getTrip(yardcountId);
    if (result instanceof Error) {
      throw result;
    } else {
      setTrip(result.yardcount);
    }
  }

  async function fetchMe() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    } else {
      setMe(result.me);
    }
  }

  useEffect(() => {
    fetchTrip();
    fetchMe();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight - 115}}>
      <Header />
      <SubPageTitle
        name={moment.utc(trip?.visitDate).format('LL')}
        name2={yardName + ' - Update Trip'}
      />
      <HiveView>
        <WarningModal
          description="Are you sure? This will permentaly delete the trip"
          onCancel={() => setShowWarning(false)}
          visible={showWarning}>
          <Button
            type="danger"
            style={{
              width: '100%',
              marginVertical: '1%',
            }}
            onPress={() => handleDelete()}>
            Delete Trip
          </Button>
        </WarningModal>
        <KeyboardAvoidingView
          style={{flex: 1}}
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          keyboardVerticalOffset={160}>
          <ScrollView ref={scrollRef}>
            <View
              style={{
                display: 'flex',
                alignSelf: 'center',
                width: '95%',
                padding: 12,
              }}>
              {trip && me ? (
                <Formik
                  initialValues={{
                    ...trip,
                    visitDate: moment.utc(trip!.visitDate).format('YYYY-MM-DD'),
                    nextVisitDate: moment
                      .utc(trip!.nextVisitDate)
                      .format('YYYY-MM-DD'),
                  }}
                  onSubmit={values => {
                    onSaveTrip(values);
                  }}>
                  {({
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    values,
                  }) => (
                    <View style={{display: 'flex'}}>
                      <View style={{marginBottom: 16}}>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Visit Date*</Text>
                          <DatePicker
                            disabled={me.role < 5}
                            onChangeText={handleChange('visitDate')}
                            date={values.visitDate}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Next Visit Date*</Text>
                          <DatePicker
                            disabled={me.role < 5}
                            onChangeText={handleChange('nextVisitDate')}
                            date={values.nextVisitDate}
                          />
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Visit Type*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('type', option)
                              }
                              onBlur={handleBlur('type')}
                              selectedValue={values.type}
                              items={[
                                {label: 'Normal', value: 'normal'},
                                {label: 'Suspicious', value: 'suspicious'},
                              ]}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>One*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('one', option)
                              }
                              onBlur={handleBlur('one')}
                              selectedValue={values.one}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>

                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Two*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('two', option)
                              }
                              onBlur={handleBlur('two')}
                              selectedValue={values.two}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Three*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('three', option)
                              }
                              onBlur={handleBlur('three')}
                              selectedValue={values.three}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Four*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('four', option)
                              }
                              onBlur={handleBlur('four')}
                              selectedValue={values.four}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Five*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('five', option)
                              }
                              onBlur={handleBlur('five')}
                              selectedValue={values.five}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Six*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('six', option)
                              }
                              onBlur={handleBlur('six')}
                              selectedValue={values.six}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Seven*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('seven', option)
                              }
                              onBlur={handleBlur('seven')}
                              selectedValue={values.seven}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Eight*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('eight', option)
                              }
                              onBlur={handleBlur('eight')}
                              selectedValue={values.eight}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Nine*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('nine', option)
                              }
                              onBlur={handleBlur('nine')}
                              selectedValue={values.nine}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>Boxes Needed*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('boxesNeeded', option)
                              }
                              onBlur={handleBlur('boxesNeeded')}
                              selectedValue={values.boxesNeeded}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>pulled*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('pulled', option)
                              }
                              onBlur={handleBlur('pulled')}
                              selectedValue={values.pulled}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>nucs*</Text>
                          <View style={{width: large ? '89.5%' : '65%'}}>
                            <Select
                              disabled={me.role < 5}
                              onValueChange={(option: string) =>
                                setFieldValue('nucs', option)
                              }
                              onBlur={handleBlur('nucs')}
                              selectedValue={values.nucs}
                              items={numerRange}
                              placeholder={{}}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            marginVertical: 5,
                          }}>
                          <Text style={{fontSize: 16}}>notes</Text>
                          <Input
                            disabled={me.role < 5}
                            style={{
                              display: 'flex',
                              width: large ? '89.5%' : '65%',
                            }}
                            multiline
                            numberOfLines={4}
                            onChangeText={handleChange('notes')}
                            onBlur={handleBlur('notes')}
                            value={values.notes}
                            maxLength={255}
                            onFocus={() => {
                              scrollRef?.current?.scrollToEnd();
                            }}
                          />
                        </View>
                      </View>
                      {me.role > 1 && (
                        <View>
                          <Button
                            style={{
                              width: '100%',
                              marginVertical: '0.5%',
                            }}
                            onPress={() => handleSubmit()}>
                            Save Trip
                          </Button>
                          <Button
                            type="danger"
                            style={{
                              width: '100%',
                            }}
                            onPress={() => setShowWarning(true)}>
                            Delete Trip
                          </Button>
                        </View>
                      )}
                    </View>
                  )}
                </Formik>
              ) : (
                <ActivityIndicator />
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </HiveView>
    </SafeAreaView>
  );
}
