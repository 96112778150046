import {useNavigation} from '@react-navigation/native';
import React, {useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
} from 'react-native';
import {
  createSubscription,
  getCompany,
  getMe,
  getPaymentMethodStatus,
  getSubscriptionStatus,
  getYards,
  newYard,
  updateSubscriptionYardQuantity,
} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {User, YardBody} from '../../types';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import {Colors, geoLocationRegex} from '../../constants';
import Icon from '../../components/icons';
const Select = require('../../components/select').default;
const useNotify = require('../../helpers/use-notify').default;
const useLocation = require('../../helpers/use-location').default;

export default function NewYard() {
  const navigation = useNavigation<any>();
  const {success, error} = useNotify();
  const userLocation = useLocation();
  const [loading, setLoading] = useState(false);
  const large = Dimensions.get('window').width > 450;

  async function onSaveYard(yardData: YardBody) {
    try {
      setLoading(true);
      const meResult = await getMe();
      if (meResult instanceof Error) {
        throw meResult;
      }

      const companyResult = await getCompany(meResult.me.companyId);
      if (companyResult instanceof Error) {
        throw companyResult;
      }
      const yardAmount = (await fetchYards(meResult.me)).length;

      const subscriptionStatus = await getSubscriptionStatus();
      if (subscriptionStatus instanceof Error) {
        throw subscriptionStatus;
      }

      const paymentMethodStatus = await getPaymentMethodStatus();
      if (paymentMethodStatus instanceof Error) {
        throw subscriptionStatus;
      }

      if (!yardData) {
        return;
      }
      if (yardAmount === 0 || companyResult.company.bypassPayment === 1) {
        const result = newYard({
          ...yardData,
          companyId: meResult.me.companyId,
          userId: meResult.me.userId,
          electricFence: +yardData.electricFence,
          hiveQuantity: +yardData.hiveQuantity,
        });
        if (result instanceof Error) {
          throw result;
        } else {
          navigation.pop();
          success('Your new yard has be successfully added');
        }
      } else {
        if (subscriptionStatus === true) {
          const result = newYard({
            ...yardData,
            companyId: meResult.me.companyId,
            userId: meResult.me.userId,
            electricFence: +yardData.electricFence,
            hiveQuantity: +yardData.hiveQuantity,
          });
          if (result instanceof Error) {
            throw result;
          } else {
            await updateSubscriptionYardQuantity({quantity: yardAmount + 1});
            navigation.pop();
            success(
              'Your new yard has be successfully added, bill will reflect additional yard',
            );
          }
        } else if (paymentMethodStatus === true) {
          const result = newYard({
            ...yardData,
            companyId: meResult.me.companyId,
            userId: meResult.me.userId,
            electricFence: +yardData.electricFence,
            hiveQuantity: +yardData.hiveQuantity,
          });
          if (result instanceof Error) {
            throw result;
          } else {
            await createSubscription({quantity: yardAmount + 1});
            navigation.pop();
            success(
              'Your new yard has be successfully added, bill will reflect additional yard',
            );
          }
        } else {
          error('Update payment method to add more yards');
        }
      }
    } catch (e) {
      error('Error saving: ' + e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchYards(me: User) {
    const result = await getYards(me.userId);
    if (result instanceof Error) {
      throw result;
    } else {
      return result.yards;
    }
  }

  return (
    <SafeAreaView>
      <Header />
      <SubPageTitle name={'Add Yard'} />
      <View style={{height: Dimensions.get('window').height - 110}}>
        <HiveView>
          <ScrollView>
            <View style={{display: 'flex', alignSelf: 'center', width: '90%'}}>
              <Formik
                initialValues={{
                  yardName: '',
                  description: '',
                  gpsLocation: '',
                  directions: '',
                  phone: '',
                  serialNumber: '',
                  hiveQuantity: 0,
                  electricFence: 0,
                }}
                onSubmit={values => {
                  if (values.yardName.length === 0) {
                    error('Yard name cannot be empty');
                  } else {
                    onSaveYard(values);
                  }
                }}>
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <View style={{display: 'flex', marginBottom: 40}}>
                    <View style={{marginBottom: 16}}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Yard Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('yardName')}
                          onBlur={handleBlur('yardName')}
                          value={values.yardName}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Description</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          multiline
                          numberOfLines={6}
                          onChangeText={handleChange('description')}
                          onBlur={handleBlur('description')}
                          value={values.description}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <View>
                          <Text
                            style={{
                              color:
                                values.gpsLocation &&
                                !geoLocationRegex.test(values.gpsLocation)
                                  ? 'red'
                                  : 'black',
                            }}>
                            GPS Location
                          </Text>
                          <Text
                            style={{
                              fontWeight: '300',
                              fontSize: 12,
                              color: 'grey',
                            }}>
                            Format (lat,long)
                          </Text>
                        </View>

                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '85%' : '49%',
                          }}
                          onChangeText={handleChange('gpsLocation')}
                          onBlur={handleBlur('gpsLocation')}
                          value={values.gpsLocation}
                        />
                        <TouchableOpacity
                          disabled={
                            !userLocation || userLocation instanceof Error
                          }
                          style={{
                            borderWidth: 1,
                            borderRadius: 3,
                            padding: 1,
                            borderColor: Colors.Border,
                          }}
                          onPress={() => {
                            setFieldValue(
                              'gpsLocation',
                              `${userLocation.lat}, ${userLocation.lng}`,
                            );
                          }}>
                          <Icon.Navigation
                            style={{
                              color:
                                !userLocation || userLocation instanceof Error
                                  ? '#d3d3d3'
                                  : 'black',
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Directions</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          multiline
                          numberOfLines={6}
                          onChangeText={handleChange('directions')}
                          onBlur={handleBlur('directions')}
                          value={values.directions}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Yard Phone</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('phone')}
                          onBlur={handleBlur('phone')}
                          value={values.phone}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Serial Number</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('serialNumber')}
                          onBlur={handleBlur('serialNumber')}
                          value={values.serialNumber}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Hive Capacity</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('hiveQuantity')}
                          onBlur={handleBlur('hiveQuantity')}
                          value={values.hiveQuantity.toString()}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Electric Fence</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            onValueChange={(option: string) =>
                              setFieldValue('electricFence', option)
                            }
                            onBlur={handleBlur('electricFence')}
                            selectedValue={values.electricFence}
                            items={[
                              {label: 'no', value: 0},
                              {label: 'yes', value: 1},
                            ]}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                    </View>
                    <Button
                      style={{
                        width: '100%',
                        marginVertical: '0.5%',
                      }}
                      onPress={() => handleSubmit()}>
                      {loading ? <ActivityIndicator /> : <Text>Save Yard</Text>}
                    </Button>
                  </View>
                )}
              </Formik>
            </View>
          </ScrollView>
        </HiveView>
      </View>
    </SafeAreaView>
  );
}
