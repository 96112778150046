import React from 'react';
import {TextInput, TextInputProps, View, Text, Dimensions} from 'react-native';
import {Colors} from '../constants';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends TextInputProps {
  disabled?: boolean;
}

export default function Input(props: Props) {
  const large = Dimensions.get('screen').width > 450;
  return !props.disabled ? (
    <TextInput
      {...composePropsWithDefaultStyles(
        {
          borderColor: Colors.Border,
          borderRadius: 5,
          borderWidth: 1,
          backgroundColor: 'white',
          paddingHorizontal: 8,
          paddingVertical: 8,
          marginVertical: 4,
          fontSize: 18,
        },
        props as any,
      )}
    />
  ) : (
    <View style={{width: large ? '85%' : '60%'}}>
      <Text
        style={{
          borderColor: Colors.Border,
          borderRadius: 5,
          borderWidth: 1,
          backgroundColor: '#d3d3d3',
          paddingHorizontal: 8,
          paddingVertical: 8,
          marginVertical: 4,
          fontSize: 18,
        }}>
        {!props.value || props.value === '' ? ' ' : props.value}
      </Text>
    </View>
  );
}
