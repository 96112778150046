import React, {useEffect, useMemo, useState} from 'react';
import {Dimensions, SafeAreaView, View} from 'react-native';
import {getMe, getYards} from '../../api';
import Header from '../../components/header';
import SubPageTitle from '../../components/subpageTitle';
import {YardMeta} from '../../types';
const useNotify = require('../../helpers/use-notify').default;
const GoogleMap = require('../../components/googlemap').default;

export default function YardLocationMap() {
  const [windowHeight, setWindowHeight] = useState<number>(
    Dimensions.get('window').height,
  );
  const [yards, setYards] = useState<YardMeta[]>([]);
  const {error} = useNotify();
  const availableYards = useMemo(() => {
    return yards.filter(({gpsLocation}) => !!gpsLocation);
  }, [yards]);

  useEffect(() => {
    async function fetch() {
      try {
        const me = await getMe();
        if (me instanceof Error) {
          throw me;
        }
        const yards = await getYards(me.me.userId);
        if (yards instanceof Error) {
          throw yards;
        }
        setYards(yards.yards);
      } catch {
        error('Unable to load yards');
      }
    }
    fetch();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1}}>
        <Header />
        <SubPageTitle name="Yard Locations Map" />
        <View
          style={{
            height: windowHeight * 0.875,
          }}>
          <GoogleMap yards={availableYards} />
        </View>
      </View>
    </SafeAreaView>
  );
}
