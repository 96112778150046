import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { YardSummary } from "../types";

interface Props {
  yardSummary: YardSummary;
}

export default function YardData({ yardSummary }: Props) {
  return (
    <View
      style={{
        display: "flex",
        alignSelf: "center",
        width: "100%",
        flexDirection: "column",
        marginVertical: 1,
      }}
    >
      <View
        style={{
          borderWidth: 1,
          borderColor: "#1167b1",
          padding: "1%",
          backgroundColor: "#187bcd",
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 20, color: "white" }}>
          {yardSummary.yardName}
        </Text>
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "center",
          width: "100%",
        }}
      >
        <View
          style={{ display: "flex", flexDirection: "column", width: "50%" }}
        >
          <View
            style={{
              borderWidth: 1,
              backgroundColor: "#d0efff",
              borderColor: "#187bcd",
            }}
          >
            <Text style={{ textAlign: "center" }}>Visited</Text>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            <Text style={{ textAlign: "center" }}>{yardSummary?.LastVist}</Text>
          </View>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "50%" }}
        >
          <View
            style={{
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            <Text style={{ textAlign: "center" }}>Next Visit</Text>
          </View>
          <View
            style={{
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            <Text style={{ textAlign: "center" }}>
              {yardSummary?.NextVisit}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            1
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.one}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            2
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.two}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            3
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.three}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            4
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.four}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            5
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.five}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            6
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.six}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            7
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.seven}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            8
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.eight}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "11.1%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            9
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.nine}
          </Text>
        </View>
      </View>

      <Text
        style={{
          textAlign: "center",
          borderWidth: 1,
          borderColor: "#187bcd",
          backgroundColor: "#d0efff",
          width: "100%",
        }}
      >
        Total: {yardSummary.total}
      </Text>
      <View style={{ display: "flex", flexDirection: "row" }}>
        <View
          style={{ display: "flex", flexDirection: "column", width: "33.3%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
              width: "100%",
            }}
          >
            Needed
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              width: "100%",
            }}
          >
            {yardSummary.needed}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "33.3%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            Pulled
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.pulled}
          </Text>
        </View>
        <View
          style={{ display: "flex", flexDirection: "column", width: "33.3%" }}
        >
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
              backgroundColor: "#d0efff",
            }}
          >
            Nucs
          </Text>
          <Text
            style={{
              textAlign: "center",
              borderWidth: 1,
              borderColor: "#187bcd",
            }}
          >
            {yardSummary.nucs}
          </Text>
        </View>
      </View>
      <View>
        <Text
          style={{
            textAlign: "center",
            borderWidth: 1,
            borderColor: "#187bcd",
            backgroundColor: "#d0efff",
            width: "100%",
          }}
        >
          Notes
        </Text>
        <Text
          style={{
            textAlign: "center",
            borderWidth: 1,
            borderColor: "#187bcd",
            width: "100%",
          }}
        >
          {yardSummary.notes}
        </Text>
      </View>
    </View>
  );
}
