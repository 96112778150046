import {useNavigation, useRoute} from '@react-navigation/native';
import React from 'react';
import {Dimensions, SafeAreaView, View, Text} from 'react-native';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import {Colors} from '../../constants';
import {newUser} from '../../api';
import {UserBody} from '../../types';
const Select = require('../../components/select').default;

export default function NewUser() {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const {companyId} = route.params;

  async function onSaveUser(user: UserBody) {
    const result = await newUser(user);
    if (result instanceof Error) {
      throw result;
    }
    navigation.pop();
  }

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height - 190}}>
      <Header />
      <SubPageTitle name="Add User" />
      <HiveView>
        <View style={{}}>
          <View
            style={{
              display: 'flex',
              alignSelf: 'center',
              width: '95%',
              padding: 12,
            }}>
            <Formik
              initialValues={{
                username: '',
                password: '',
                confirmPassword: '',
                email: '',
                active: 1,
                role: 1,
              }}
              onSubmit={values => {
                if (values.password === values.confirmPassword) {
                  onSaveUser({...values, companyId: companyId});
                }
              }}>
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setFieldValue,
              }) => (
                <View style={{display: 'flex'}}>
                  <View style={{marginBottom: 16}}>
                    <View style={{marginVertical: 4}}>
                      <Text>Username*</Text>
                      <Input
                        style={{
                          display: 'flex',
                          width: '100%',
                        }}
                        multiline
                        numberOfLines={1}
                        onChangeText={handleChange('username')}
                        onBlur={handleBlur('username')}
                        value={values.username}
                      />
                    </View>
                    <View style={{marginVertical: 4}}>
                      <Text>Email*</Text>
                      <Input
                        style={{
                          display: 'flex',
                          width: '100%',
                        }}
                        multiline
                        numberOfLines={1}
                        onChangeText={handleChange('email')}
                        onBlur={handleBlur('email')}
                        value={values.email}
                      />
                    </View>
                    <View style={{marginVertical: 4}}>
                      <Text>Password*</Text>
                      <Input
                        style={{
                          display: 'flex',
                          width: '100%',
                        }}
                        multiline
                        numberOfLines={1}
                        onChangeText={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                      />
                    </View>
                    <View style={{marginVertical: 4}}>
                      <Text>Confirm Password*</Text>
                      <Input
                        style={{
                          display: 'flex',
                          width: '100%',
                        }}
                        multiline
                        numberOfLines={1}
                        onChangeText={handleChange('confirmPassword')}
                        onBlur={handleBlur('confirmPassword')}
                        value={values.confirmPassword}
                      />
                    </View>
                    <View style={{marginVertical: 4}}>
                      <Text>Active*</Text>
                      <Select
                        onValueChange={(option: string) =>
                          setFieldValue('active', option)
                        }
                        onBlur={handleBlur('active')}
                        selectedValue={values.active}
                        items={[
                          {label: 'no', value: 0},
                          {label: 'yes', value: 1},
                        ]}
                        placeholder={{}}
                      />
                    </View>
                    <View style={{marginVertical: 4}}>
                      <Text>Role*</Text>
                      <Select
                        onValueChange={(option: string) =>
                          setFieldValue('role', option)
                        }
                        onBlur={handleBlur('role')}
                        selectedValue={values.role}
                        items={[
                          {label: 'Reader', value: 1},
                          {label: 'Contributor', value: 5},
                          {label: 'Administrator', value: 10},
                        ]}
                        placeholder={{}}
                      />
                    </View>
                  </View>

                  <Button
                    style={{
                      width: '100%',
                      marginVertical: '0.5%',
                    }}
                    onPress={() => handleSubmit()}>
                    Add User
                  </Button>
                </View>
              )}
            </Formik>
          </View>
        </View>
      </HiveView>
    </SafeAreaView>
  );
}
