import {useNavigation} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import Clickable from '../components/clickable';
import Header from '../components/header';
import {Heading1} from '../components/headings';
import Icon from '../components/icons';
import {Colors} from '../constants';
import AuthContext from '../context/auth.context';
import storage from '../storage/storage.isomorphic';

export default function Menu() {
  const [windowHeight] = useState<number>(Dimensions.get('window').height);
  const [windowHWidth] = useState<number>(Dimensions.get('window').width);
  const {setToken} = useContext(AuthContext);
  const navigation = useNavigation<any>();

  async function onPressLogout() {
    await storage.clear();
    setToken(null);
  }

  return (
    <SafeAreaView>
      <View
        style={{
          height: windowHeight,
          backgroundColor: Colors.Black,
          padding: 20,
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
          }}>
          <Heading1>Menu</Heading1>
          <TouchableOpacity onPress={() => navigation.goBack()}>
            <Icon.X color={Colors.White} size={24} />
          </TouchableOpacity>
        </View>
        <View style={{padding: '10%'}}>
          <Clickable onPress={() => navigation.replace('Home')}>
            <Icon.Home color="white" size={24} />
            <Text style={{color: Colors.White, fontSize: 24, paddingLeft: 10}}>
              Yards
            </Text>
          </Clickable>
          <View
            style={{borderBottomWidth: 1, borderBottomColor: Colors.White}}
          />

          <Clickable onPress={() => navigation.replace('Reports')}>
            <Icon.Book color="white" size={24} />
            <Text style={{color: Colors.White, fontSize: 24, paddingLeft: 10}}>
              Reports
            </Text>
          </Clickable>
          <View
            style={{borderBottomWidth: 1, borderBottomColor: Colors.White}}
          />

          <Clickable onPress={() => navigation.replace('Info')}>
            <Icon.Info color="white" size={24} />
            <Text style={{color: Colors.White, fontSize: 24, paddingLeft: 10}}>
              Help
            </Text>
          </Clickable>
          <View
            style={{borderBottomWidth: 1, borderBottomColor: Colors.White}}
          />
          <Clickable onPress={() => navigation.replace('Settings')}>
            <Icon.Settings color="white" size={24} />
            <Text style={{color: Colors.White, fontSize: 24, paddingLeft: 10}}>
              Settings
            </Text>
          </Clickable>
          <View
            style={{borderBottomWidth: 1, borderBottomColor: Colors.White}}
          />
          <Clickable onPress={onPressLogout}>
            <Icon.LogOut color="white" size={24} />
            <Text style={{color: Colors.White, fontSize: 24, paddingLeft: 10}}>
              Log Out
            </Text>
          </Clickable>
        </View>
      </View>
    </SafeAreaView>
  );
}
