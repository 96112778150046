import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import {
  deleteYard,
  getMe,
  getSubscriptionStatus,
  getYard,
  getYards,
  updateSubscriptionYardQuantity,
  updateYard,
} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {YardBody, User} from '../../types';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import {Colors, geoLocationRegex} from '../../constants';
import WarningModal from '../../components/warningModal';
import Icon from '../../components/icons';
const useNotify = require('../../helpers/use-notify').default;
const useLocation = require('../../helpers/use-location').default;
const Select = require('../../components/select').default;

export default function EditYard() {
  const [yard, setYard] = useState<YardBody>();
  const userLocation = useLocation();
  const [me, setMe] = useState<User>();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const windowHeight = Dimensions.get('window').height;
  const {success, error} = useNotify();
  const {yardId} = route.params;
  const [showWarning, setShowWarning] = useState(false);
  const large = Dimensions.get('window').width > 450;

  async function handleDelete() {
    try {
      const yardAmount = (await fetchYards(me!)).length;
      const subscriptionStatus = await getSubscriptionStatus();
      if (subscriptionStatus instanceof Error) {
        throw subscriptionStatus;
      }
      if (subscriptionStatus) {
        const subscriptionResult = await updateSubscriptionYardQuantity({
          quantity: yardAmount - 1,
        });
        const yardResult = await deleteYard(yardId);
        navigation.pop();
        if (subscriptionResult instanceof Error) {
          throw subscriptionResult;
        }
        if (yardResult instanceof Error) {
          throw yardResult;
        }
        success('Your yard has been deleted');
      } else {
        const yardResult = await deleteYard(yardId);
        if (yardResult instanceof Error) {
          throw yardResult;
        }
        navigation.pop();
        success('Your yard has been deleted');
      }
    } catch (e) {
      error('Error deleting yard: ' + e);
    }
  }

  async function fetchYards(me: User) {
    const result = await getYards(me.userId);
    if (result instanceof Error) {
      throw result;
    } else {
      return result.yards;
    }
  }

  async function onSaveYard(updatedYard: YardBody) {
    try {
      if (
        updatedYard.gpsLocation &&
        !geoLocationRegex.test(updatedYard.gpsLocation)
      ) {
        error('GPS Location must be formated (lat, long) or left empty');
      } else {
        const result = await updateYard(yardId, {
          ...updatedYard,
          hiveQuantity: +updatedYard.hiveQuantity,
          electricFence: +updatedYard.electricFence,
        });
        if (result instanceof Error) {
          throw result;
        } else {
          success('Your yard has been updated');
        }
      }
    } catch (e) {
      error('Error saving: ' + e);
    }
  }

  async function fetchYard() {
    const result = await getYard(yardId);
    if (result instanceof Error) {
      throw result;
    }

    setYard(result.yard);
  }

  async function fetchMe() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    }

    setMe(result.me);
  }

  useEffect(() => {
    fetchYard();
    fetchMe();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight - 160}}>
      <Header />
      <SubPageTitle name={yard?.yardName ?? ''} />
      <View
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          height: 50,
        }}>
        <TouchableOpacity
          style={{
            backgroundColor: '#1167b1',
            width: '49.9%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => navigation.push('Trips', {yardId: yardId})}>
          <Text style={{color: Colors.White, fontSize: 16}}>Trips</Text>
        </TouchableOpacity>
        <View style={{borderRightColor: Colors.Black, borderRightWidth: 1}} />
        <TouchableOpacity
          style={{
            backgroundColor: '#1167b1',
            width: '50%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => navigation.push('Hives', {yardId: yardId})}>
          <Text style={{color: Colors.White, fontSize: 16}}>Hives</Text>
        </TouchableOpacity>
      </View>
      <HiveView>
        <WarningModal
          description="Are you sure? This will permentaly delete the yard"
          onCancel={() => setShowWarning(false)}
          visible={showWarning}>
          <Button
            type="danger"
            style={{
              width: '100%',
              marginVertical: '1%',
            }}
            onPress={() => handleDelete()}>
            Delete Yard
          </Button>
        </WarningModal>
        <ScrollView>
          <View
            style={{
              display: 'flex',
              alignSelf: 'center',
              width: '95%',
              padding: 12,
            }}>
            {yard && me ? (
              <Formik
                initialValues={yard}
                onSubmit={values => {
                  if (values.yardName.length === 0) {
                    error('Yard name cannot be empty');
                  } else {
                    onSaveYard(values);
                  }
                }}>
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <View style={{display: 'flex'}}>
                    <View style={{marginBottom: 16}}>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Yard Name*</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('yardName')}
                          onBlur={handleBlur('yardName')}
                          value={values.yardName}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Description</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          multiline
                          numberOfLines={6}
                          onChangeText={handleChange('description')}
                          onBlur={handleBlur('description')}
                          value={values.description}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <View>
                          <Text
                            style={{
                              color:
                                values.gpsLocation &&
                                !geoLocationRegex.test(values.gpsLocation)
                                  ? 'red'
                                  : 'black',
                            }}>
                            GPS Location
                          </Text>
                          <Text
                            style={{
                              fontWeight: '300',
                              fontSize: 12,
                              color: 'grey',
                            }}>
                            Format (lat,long)
                          </Text>
                        </View>

                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '85%' : '49%',
                          }}
                          onChangeText={handleChange('gpsLocation')}
                          onBlur={handleBlur('gpsLocation')}
                          value={values.gpsLocation}
                          disabled={me.role < 10}
                        />
                        <TouchableOpacity
                          disabled={
                            !userLocation || userLocation instanceof Error
                          }
                          style={{
                            borderWidth: 1,
                            borderRadius: 3,
                            padding: 1,
                            borderColor: Colors.Border,
                          }}
                          onPress={() => {
                            setFieldValue(
                              'gpsLocation',
                              `${userLocation.lat}, ${userLocation.lng}`,
                            );
                          }}>
                          <Icon.Navigation
                            style={{
                              color:
                                !userLocation || userLocation instanceof Error
                                  ? '#d3d3d3'
                                  : 'black',
                            }}
                          />
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Directions</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          multiline
                          numberOfLines={6}
                          onChangeText={handleChange('directions')}
                          onBlur={handleBlur('directions')}
                          value={values.directions}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Yard Phone</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('phone')}
                          onBlur={handleBlur('phone')}
                          value={values.phone}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Serial Number</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('serialNumber')}
                          onBlur={handleBlur('serialNumber')}
                          value={values.serialNumber}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Hive Capacity</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: large ? '90%' : '65%',
                          }}
                          onChangeText={handleChange('hiveQuantity')}
                          onBlur={handleBlur('hiveQuantity')}
                          value={values.hiveQuantity.toString()}
                          disabled={me.role < 10}
                        />
                      </View>
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginVertical: 5,
                        }}>
                        <Text>Electric Fence</Text>
                        <View style={{width: large ? '90%' : '65%'}}>
                          <Select
                            onValueChange={(option: string) =>
                              setFieldValue('electricFence', option)
                            }
                            onBlur={handleBlur('electricFence')}
                            selectedValue={values.electricFence}
                            items={[
                              {label: 'no', value: 0},
                              {label: 'yes', value: 1},
                            ]}
                            disabled={me.role < 10}
                            placeholder={{}}
                          />
                        </View>
                      </View>
                    </View>
                    {me.role > 5 && (
                      <View>
                        <Button
                          style={{
                            width: '100%',
                            marginVertical: '0.5%',
                          }}
                          onPress={() => handleSubmit()}>
                          Save Yard
                        </Button>
                        <Button
                          type="danger"
                          style={{
                            width: '100%',
                          }}
                          onPress={() => setShowWarning(true)}>
                          Delete Yard
                        </Button>
                      </View>
                    )}
                  </View>
                )}
              </Formik>
            ) : (
              <ActivityIndicator />
            )}
          </View>
        </ScrollView>
      </HiveView>
    </SafeAreaView>
  );
}
