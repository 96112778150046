import React from 'react';
import {
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewProps,
} from 'react-native';
import {Colors} from '../constants';
import composePropsWithDefaultStyles from '../helpers/compose-props-with-default-styles';

interface Props extends TouchableOpacityProps {}

export default function Clickable(props: Props) {
  return (
    <TouchableOpacity
      {...composePropsWithDefaultStyles(
        {
          paddingVertical: 8,
        },
        props as any,
      )}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginVertical: 12,
          alignItems: 'center',
          marginBottom: 10,
        }}>
        {props.children}
      </View>
    </TouchableOpacity>
  );
}
