import React from "react";
import { Text, TextProps, View } from "react-native";
import { Colors } from "../constants";

import composePropsWithDefaultStyles from "../helpers/compose-props-with-default-styles";

interface Props extends TextProps {}

export function Heading1(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontWeight: "500" as const,
          fontSize: 32,
          color: Colors.White,
          marginVertical: 5,
        },
        props as any
      )}
    >
      {props.children}
    </Text>
  );
}

export function Heading3(props: Props) {
  return (
    <Text
      {...composePropsWithDefaultStyles(
        {
          fontWeight: "600" as const,
          fontSize: 16,
          color: Colors.Black,
        },
        props as any
      )}
    />
  );
}

export function Heading5(props: Props) {
  return (
    <View>
      <Text
        {...composePropsWithDefaultStyles(
          {
            fontWeight: "400" as const,
            fontSize: 12,
            color: Colors.Black,
          },
          props as any
        )}
      />
    </View>
  );
}

export default function Heading() {}
