import React, {useContext, useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import {deleteUser, getMe, updateUser} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import Input from '../../components/input';
import {User} from '../../types';
import moment from 'moment';
import {Colors} from '../../constants';
import SubPageTitle from '../../components/subpageTitle';
import HiveView from '../../components/hive-view';
const useNotify = require('../../helpers/use-notify').default;
import storage from '../../storage/storage.isomorphic';
import AuthContext from '../../context/auth.context';
import WarningModal from '../../components/warningModal';

export default function UpdateProfile() {
  const [me, setMe] = useState<User>();
  const windowHeight = Dimensions.get('window').height;
  const [showWarning, setShowWarning] = useState(false);
  const {setToken} = useContext(AuthContext);
  const {success, error} = useNotify();

  async function handleDelete() {
    if (!me) {
      return;
    }
    const result = await deleteUser(me.userId);
    if (result instanceof Error) {
      error('Company has not been deleted!');
      throw result;
    } else {
      await storage.clear();
      setToken(null);
    }
  }

  async function onSaveProfile() {
    if (me) {
      const result = await updateUser(me?.userId, me);
      if (result instanceof Error) {
        error('Profile has not been updated!');
        throw result;
      } else {
        setToken(null);
        success('Profile has been updated!');
      }
    }
  }

  async function fetchUser() {
    const result = await getMe();
    if (result instanceof Error) {
      throw result;
    }
    setMe(result.me);
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1, alignItems: 'center'}}>
        <Header />
        <SubPageTitle name="Settings" name2="Update Profile" />
        <View
          style={{
            height: windowHeight - 140,
            width: '100%',
          }}>
          {me ? (
            <HiveView>
              <WarningModal
                description="Are you sure? This will permentaly delete your account. If you are the last administrator of the company, the company will automatically be deleted along with account."
                onCancel={() => setShowWarning(false)}
                visible={showWarning}>
                <Button
                  type="danger"
                  style={{
                    width: '100%',
                    marginVertical: '1%',
                  }}
                  onPress={() => handleDelete()}>
                  Delete User
                </Button>
              </WarningModal>
              <View
                style={{
                  paddingVertical: 16,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: windowHeight - 140,
                }}>
                <View
                  style={{
                    width: '90%',
                  }}>
                  <View style={{marginVertical: 4}}>
                    <Text>Username</Text>
                    <Text
                      style={{
                        borderColor: Colors.Border,
                        borderRadius: 5,
                        borderWidth: 1,
                        backgroundColor: '#d3d3d3',
                        paddingHorizontal: 8,
                        paddingVertical: 8,
                        marginVertical: 4,
                        fontSize: 18,
                      }}>
                      {me?.username}
                    </Text>
                  </View>

                  <View style={{marginVertical: 4}}>
                    <Text>Email</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      value={me?.email}
                      onChange={v => setMe({...me, email: v.nativeEvent.text})}
                    />
                  </View>

                  <View style={{marginVertical: 4}}>
                    <Text>Created on</Text>
                    <Text
                      style={{
                        borderColor: Colors.Border,
                        borderRadius: 5,
                        borderWidth: 1,
                        backgroundColor: '#d3d3d3',
                        paddingHorizontal: 8,
                        paddingVertical: 8,
                        marginVertical: 4,
                        fontSize: 18,
                      }}>
                      {moment(me?.createDate).format('LL')}
                    </Text>
                  </View>
                </View>
                <Button style={{width: '90%'}} onPress={() => onSaveProfile()}>
                  Save Profile
                </Button>
              </View>
              <TouchableOpacity
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  alignSelf: 'center',
                }}
                onPress={() => setShowWarning(true)}>
                <Text style={{color: 'red', textDecorationLine: 'underline'}}>
                  Delete Account
                </Text>
              </TouchableOpacity>
            </HiveView>
          ) : (
            <ActivityIndicator />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
