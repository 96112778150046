import {NavigationContainer} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, {useContext} from 'react';
import AuthContext from './context/auth.context';
import EditHive from './screens/hive/edit-hive';
import NewHive from './screens/hive/new-hive';
import Hives from './screens/hives';
import Home from './screens/home';
import Info from './screens/info';
import Loading from './screens/loading';
import Reports from './screens/reports';
import OverdueSummary from './screens/reports/overdue-summary';
import TodaySummary from './screens/reports/today-summary';
import UpcomingSummary from './screens/reports/upcoming-summary';
import YardLocationMap from './screens/reports/yard-location';
import YardSummary from './screens/reports/yard-summary';
import YesterdaySummary from './screens/reports/yesterday-summary';
import Settings from './screens/settings';
import CompanyManagement from './screens/settings/company-management';
import UpdatePassword from './screens/settings/update-password';
import UpdateProfile from './screens/settings/update-profile';
import EditTrip from './screens/trip/edit-trip';
import NewTrip from './screens/trip/new-trip';
import Trips from './screens/trips';
import Menu from './screens/menu';
import NewYard from './screens/yard/new-yard';
import Register from './screens/register';
import NewCompany from './screens/settings/new-company';
import UpdateCompany from './screens/settings/update-company';
import Users from './screens/settings/users';
import UpdateUser from './screens/settings/update-user';
import NewUser from './screens/settings/new-user';
import PaymentManagement from './screens/settings/payment-management';
const PaymentUpdate = require('./screens/settings/payment-update').default;
import Login from './screens/login';
import EditYard from './screens/yard/edit-yard';
import NotesReport from './screens/reports/notes-report';

const GuestStack = createNativeStackNavigator();
const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['https://app.hivetrak.ca', 'hivetrak://'],
  config: {
    screens: {
      Home: '/',
      Login: '/login',
      Register: '/register',
      Info: '/info',
      Yard: '/yard',
      Trips: '/trips',
      Trip: '/trips/trip',
      Hives: '/hives',
      Hive: '/hives/hive',
      Reports: '/reports',
      MenuPage: '/menu',
      NewYard: '/yards/newyard',
      NewTrip: '/trips/newtrip',
      NewHive: '/hives/newhive',
      YardSummary: '/reports/yardsummary',
      YesterdaySummary: '/reports/yesterdaySummary',
      TodaySummary: '/reports/todaySummary',
      UpcomingSummary: '/reports/upcomingSummary',
      OverdueSummary: '/reports/overdueSummary',
      YardLocationMap: '/reports/yardLocationMap',
      Settings: '/settings',
      Company: '/settings/company',
      Profile: '/settings/profile',
      Password: '/settings/password',
      CompanyManagement: '/settings/companyManagement',
      LicenseManagement: '/settings/licenseManagement',
      Users: '/settings/companyManagement/company/users',
      User: '/settings/companyManagement/company/users/user',
      NewCompany: '/setttings/companyManagement/newCompany',
      NewUser: '/setttings/companyManagement/company/users/newUser',
      PaymentUpdate: '/settings/paymentManagement/update',
    },
  },
};

export default function Router() {
  const {token, loaded} = useContext(AuthContext);

  if (!loaded) {
    return <Loading />;
  }

  return (
    <NavigationContainer linking={linking} fallback={<Loading />}>
      {token ? (
        <Stack.Navigator>
          <Stack.Screen
            name="Home"
            component={Home}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Info"
            component={Info}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Yard"
            component={EditYard}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Trips"
            component={Trips}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Trip"
            component={EditTrip}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Hives"
            component={Hives}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Hive"
            component={EditHive}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Menu"
            component={Menu}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Reports"
            component={Reports}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NewYard"
            component={NewYard}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NewTrip"
            component={NewTrip}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NewHive"
            component={NewHive}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="YardSummary"
            component={YardSummary}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="YesterdaySummary"
            component={YesterdaySummary}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="TodaySummary"
            component={TodaySummary}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="UpcomingSummary"
            component={UpcomingSummary}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="OverdueSummary"
            component={OverdueSummary}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="YardLocationMap"
            component={YardLocationMap}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Settings"
            component={Settings}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Company"
            component={UpdateCompany}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Profile"
            component={UpdateProfile}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Password"
            component={UpdatePassword}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Users"
            component={Users}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="User"
            component={UpdateUser}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NewCompany"
            component={NewCompany}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NewUser"
            component={NewUser}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="CompanyManagement"
            component={CompanyManagement}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PaymentManagement"
            component={PaymentManagement}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PaymentUpdate"
            component={PaymentUpdate}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="NotesReport"
            component={NotesReport}
            options={{headerShown: false}}
          />
        </Stack.Navigator>
      ) : (
        <GuestStack.Navigator>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="Register"
            component={Register}
            options={{headerShown: false}}
          />
        </GuestStack.Navigator>
      )}
    </NavigationContainer>
  );
}
