import config from './config';
import Fetcher, {FetchError} from './helpers/fetcher';
import storage from './storage/storage.isomorphic';
import {
  Yardcount,
  User,
  Yard,
  YardMeta,
  Hive,
  YardBody,
  TripMeta,
  HiveMeta,
  RegisterData,
  Company,
  ResetPassword,
  CompanyBody,
  UserBody,
  Invoice,
  PaymentMethodPreview,
} from './types';

const fetcher = new Fetcher({
  baseUrl: config.backendUrl,
  getAccessToken: () => storage.getItem('access_token'),
});

export function apiResult<T>(result: T | FetchError): T {
  if (result instanceof Error) {
    throw result;
  }
  return result;
}

export function login(email: string, password: string) {
  return fetcher.fetch<{accessToken: string; refreshToken: string}>(
    '/authorize',
    {method: 'POST', body: {email, password}},
  );
}
export function register(body: RegisterData) {
  return fetcher.fetch<{user: User}>('/register', {
    method: 'POST',
    body,
  });
}

export function getYards(userId: number) {
  return fetcher.fetch<{yards: YardMeta[]}>(`/user/${userId}/yards`);
}

export function getTrips(yardId: number) {
  return fetcher.fetch<{yardcounts: Yardcount[]}>(`/yard/${yardId}/yardcounts`);
}

export function getHives(yardId: number) {
  return fetcher.fetch<{hives: Hive[]}>(`/yard/${yardId}/hives`);
}

export function getCompanies() {
  return fetcher.fetch<{companies: Company[]}>('/company');
}

export function getMe() {
  return fetcher.fetch<{me: User}>('/me');
}

export function updateYard(yardId: number, body: YardBody) {
  return fetcher.fetch<{yard: Yard}>(`/yard/${yardId}`, {
    method: 'PATCH',
    body,
  });
}

export function deleteUser(userId: number) {
  return fetcher.fetch(`/user/${userId}`, {
    method: 'DELETE',
  });
}

export function deleteCompany(companyId: number) {
  return fetcher.fetch(`/company/${companyId}`, {
    method: 'DELETE',
  });
}

export function deleteYard(yardId: number) {
  return fetcher.fetch(`/yard/${yardId}`, {
    method: 'DELETE',
  });
}

export function deleteTrip(yardcountId: number) {
  return fetcher.fetch(`/yardcount/${yardcountId}`, {
    method: 'DELETE',
  });
}

export function deleteHive(hiveId: number) {
  return fetcher.fetch(`/hive/${hiveId}`, {
    method: 'DELETE',
  });
}

export function getYard(yardId: number) {
  return fetcher.fetch<{yard: Yard}>(`/yard/${yardId}`);
}

export function getTrip(yardcountId: number) {
  return fetcher.fetch<{yardcount: Yardcount}>(`/yardcount/${yardcountId}`);
}

export function updateTrip(
  yardcountId: number,
  body: Omit<Yardcount, 'yardcountId'>,
) {
  return fetcher.fetch<{yardcount: Yardcount}>(`/yardcount/${yardcountId}`, {
    method: 'PATCH',
    body,
  });
}

export function getHive(hiveId: number) {
  return fetcher.fetch<{hive: Hive}>(`/hive/${hiveId}`);
}

export function updateHive(hiveId: number, body: Omit<Hive, 'hiveId'>) {
  return fetcher.fetch<{hive: Hive}>(`/hive/${hiveId}`, {
    method: 'PATCH',
    body,
  });
}

export function newYard(body: Omit<Yard, 'yardId'>) {
  return fetcher.fetch<{yard: Yard}>('/yard', {
    method: 'POST',
    body,
  });
}

export function newTrip(body: TripMeta) {
  return fetcher.fetch<{yardcount: Yardcount}>('/yardcount', {
    method: 'POST',
    body,
  });
}

export function newHive(body: HiveMeta) {
  return fetcher.fetch<{hive: Hive}>('/hive', {method: 'POST', body});
}

export function getCompany(companyId: number) {
  return fetcher.fetch<{company: Company}>(`/company/${companyId}`);
}

export function updateCompany(
  companyId: number,
  body: Omit<Company, 'companyId'>,
) {
  return fetcher.fetch<{company: Company}>(`company/${companyId}`, {
    method: 'PATCH',
    body,
  });
}

export function updateUser(userId: number, body: Omit<User, 'userId'>) {
  return fetcher.fetch<{user: User}>(`user/${userId}`, {
    method: 'PATCH',
    body,
  });
}

export function updatePassword(userId: number, body: ResetPassword) {
  return fetcher.fetch(`user/${userId}/password`, {
    method: 'PATCH',
    body,
  });
}
export function updatePasswordAdmin(userId: number, body: {password: string}) {
  return fetcher.fetch(`user/${userId}/admin-password`, {
    method: 'PATCH',
    body,
  });
}

export function getUsers(companyId: number) {
  return fetcher.fetch<{users: User[]}>(`company/${companyId}/users`);
}

export function getUser(userId: number) {
  return fetcher.fetch<{user: User}>(`user/${userId}`);
}

export function newCompany(body: CompanyBody) {
  return fetcher.fetch<{company: Company}>('company', {
    method: 'POST',
    body,
  });
}

export function newUser(body: UserBody) {
  return fetcher.fetch<{user: User}>('user', {
    method: 'POST',
    body,
  });
}

export function createSubscription(body: {quantity: number}) {
  return fetcher.fetch<{customerId: string; subscriptionId: string}>(
    'stripe/subscription',
    {
      method: 'POST',
      body,
    },
  );
}

export function updateSubscriptionPaymentMethod(body: {
  paymentMethodId: string;
}) {
  return fetcher.fetch<{paymentMethodId: string}>(
    'stripe/subscription/payment-method',
    {
      method: 'PATCH',
      body,
    },
  );
}

export function updateSubscriptionYardQuantity(body: {quantity: number}) {
  return fetcher.fetch<{customerId: string; subscriptionId: string}>(
    '/stripe/subscription/quantity',
    {
      method: 'PATCH',
      body,
    },
  );
}

export function getSubscriptionStatus() {
  return fetcher.fetch<boolean>('/company-subscription/status');
}

export function getPaymentMethodStatus() {
  return fetcher.fetch<boolean>('/company-payment-method/status');
}

export function getUpcomingInvoice() {
  return fetcher.fetch<{invoice: Invoice}>(
    '/stripe/subscription/upcoming-invoice',
  );
}

export function getPaymentMethodPreview() {
  return fetcher.fetch<{paymentMethodPreview: PaymentMethodPreview}>(
    '/stripe/subscription/card-preview',
  );
}

export function cancelSubscription() {
  return fetcher.fetch('/stripe/subscription/cancel-at-period-end', {
    method: 'PATCH',
  });
}
