import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View, Text, TouchableOpacity, Dimensions} from 'react-native';
import Icon from './icons';

interface Props {
  name: string;
  name2?: string;
}

export default function SubPageTitle({name, name2}: Props) {
  const navigation = useNavigation<any>();
  const windowHeight = Dimensions.get('window').height;

  return (
    <View
      style={{
        height: windowHeight * 0.06,
        borderColor: '#373535',
        borderWidth: 2,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#373535',
        paddingHorizontal: 16,
      }}>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Icon.ArrowLeftCircle color="white" size={20} />
      </TouchableOpacity>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Text style={{color: 'white', fontSize: 18}}>{name}</Text>
        <Text style={{color: 'white', fontSize: 18, fontWeight: '300'}}>
          {name2}
        </Text>
      </View>
      <View />
    </View>
  );
}
