import React, {PropsWithChildren} from 'react';
import {ImageBackground} from 'react-native';

export default function HiveView({children}: PropsWithChildren<{}>) {
  return (
    <ImageBackground
      source={{uri: 'https://app.hivetrak.ca/images/back-2.png'}}
      style={{height: '100%'}}
      imageStyle={{resizeMode: 'repeat', opacity: 0.5}}>
      {children}
    </ImageBackground>
  );
}
