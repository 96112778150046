import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useMemo, useState} from 'react';
import {Platform} from 'react-native';
import {Dimensions, FlatList, Text, TouchableOpacity, View} from 'react-native';
import {getCompany, getMe, getSubscriptionStatus, getYards} from '../../api';
import BigLoading from '../../components/big-loading';
import Button from '../../components/button';
import Card from '../../components/card';
import {Company, User, YardMeta} from '../../types';
import moment from 'moment';
import {Colors, YardStatus} from '../../constants';
import Badge from '../../components/badge';
import {useDebounce} from '../../helpers/use-debounce';

export default function HomeYards() {
  const {debounce} = useDebounce();
  const windowHeight = Dimensions.get('window').height;
  const [yards, setYards] = useState<YardMeta[]>();
  const [lockYards, setLockYards] = useState<boolean>();
  const [me, setMe] = useState<User>();
  const [company, setCompany] = useState<Company>();
  const [subscriptionStatus, setSubscriptionStatus] = useState<boolean>();
  const navigation = useNavigation<any>();
  const mobile = Platform.OS === 'ios' || Platform.OS === 'android';
  const processedYards = useMemo(() => {
    return yards
      ?.map(yard => {
        const nextVisit =
          yard.trips.sort((a, b) =>
            moment(b.visitDate)
              .format()
              .localeCompare(moment.utc(a.visitDate).format()),
          )[0]?.nextVisitDate ?? '';
        const lastVisit =
          yard.trips.sort((a, b) =>
            moment(b.visitDate)
              .format()
              .localeCompare(moment.utc(a.visitDate).format()),
          )[0]?.visitDate ?? '';
        return {
          ...yard,
          nextVisit,
          lastVisit,
          ...(moment.utc().diff(nextVisit, 'days') > 0
            ? {
                status: YardStatus.OverdueVisit,
              }
            : moment.utc().diff(nextVisit, 'days') <= 0 &&
              moment.utc().diff(nextVisit, 'days') > -2
            ? {
                status: YardStatus.UpcomingVisit,
              }
            : {
                status: YardStatus.NoStatus,
              }),
        };
      })
      .sort((b, a) => b.nextVisit.localeCompare(a.nextVisit));
  }, [yards]);

  async function fetchData() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }
    setMe(meResult.me);
    const companyResult = await getCompany(meResult.me.companyId!);
    if (companyResult instanceof Error) {
      throw companyResult;
    }
    setCompany(companyResult.company);
    const yardsResult = await getYards(meResult.me.userId);
    if (yardsResult instanceof Error) {
      throw yardsResult;
    }
    setYards(yardsResult.yards);
    const subscriptionResult = await getSubscriptionStatus();
    if (subscriptionResult instanceof Error) {
      throw subscriptionResult;
    }
    setSubscriptionStatus(subscriptionResult);

    if (
      !subscriptionResult &&
      companyResult.company.bypassPayment === 0 &&
      yardsResult.yards.length > 1
    ) {
      setLockYards(true);
    } else {
      setLockYards(false);
    }
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
    });

    return unsubscribe;
  }, [navigation]);

  if (!processedYards) {
    return <BigLoading />;
  }

  return (
    <View
      style={{
        position: 'relative',
        flex: 1,
      }}>
      <View
        style={{height: mobile ? windowHeight * 0.75 : windowHeight * 0.87}}>
        {me && me.role > 5 && (
          <TouchableOpacity
            style={{
              backgroundColor: '#1167b1',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: '8%',
            }}
            onPress={() => debounce(navigation.push('NewYard'))}>
            <Text style={{color: Colors.White, fontSize: 16}}>New Yard</Text>
          </TouchableOpacity>
        )}
        {lockYards && (
          <Text style={{textAlign: 'center', color: 'red', fontSize: 18}}>
            *Add payment method to subscribe and unlock yards*
          </Text>
        )}
        <FlatList
          data={processedYards}
          keyExtractor={(item, index) => item.yardId.toString()}
          renderItem={({
            item: {yardName, nextVisit, lastVisit, yardId, status},
            index,
          }) => (
            <TouchableOpacity
              disabled={lockYards}
              key={index}
              style={{alignItems: 'center'}}
              onPress={() => navigation.push('Yard', {yardId: yardId})}>
              <Card
                style={{
                  width: '95%',
                  marginVertical: 8,
                  marginHorizontal: 4,
                  paddingHorizontal: 16,
                  paddingVertical: 16,
                  backgroundColor: !lockYards ? '#FFFFFA' : '#bfbfbf',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: 10,
                  }}>
                  <Text style={{fontSize: 18}}>{yardName}</Text>
                  {status === YardStatus.OverdueVisit ? (
                    <Badge type="danger">{status}</Badge>
                  ) : (
                    status === YardStatus.UpcomingVisit && (
                      <Badge type="general">{status}</Badge>
                    )
                  )}
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}>
                    <Text style={{fontSize: 14, color: Colors.Gray}}>
                      Last Visited
                    </Text>
                    <Text style={{fontSize: 16}}>
                      {lastVisit
                        ? moment.utc(lastVisit).format('LL')
                        : 'Unknown Date'}
                    </Text>
                  </View>

                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                    }}>
                    <Text style={{fontSize: 14, color: Colors.Gray}}>
                      Next Visit
                    </Text>
                    <Text style={{fontSize: 16}}>
                      {nextVisit
                        ? moment.utc(nextVisit).format('LL')
                        : 'Unknown Date'}
                    </Text>
                  </View>
                </View>
              </Card>
            </TouchableOpacity>
          )}
        />
      </View>
    </View>
  );
}
