import {useNavigation, useRoute} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import {
  deleteUser,
  getMe,
  getUser,
  updatePasswordAdmin,
  updateUser,
} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import Input from '../../components/input';
import {User} from '../../types';
import {Formik} from 'formik';
import SubPageTitle from '../../components/subpageTitle';
import {Colors} from '../../constants';
import moment from 'moment';
import WarningModal from '../../components/warningModal';
const useNotify = require('../../helpers/use-notify').default;
const Select = require('../../components/select').default;
import storage from '../../storage/storage.isomorphic';
import AuthContext from '../../context/auth.context';

export default function UpdateUser() {
  const [user, setUser] = useState<User>();
  const route = useRoute<any>();
  const {success, error} = useNotify();
  const [showWarning, setShowWarning] = useState(false);
  const {userId} = route.params;
  const {setToken} = useContext(AuthContext);
  const navigation = useNavigation<any>();

  async function handleDelete() {
    if (!userId) {
      return;
    }
    const meResult = await getMe();
    if (meResult instanceof Error) {
      error('Company has not been deleted!');
      throw meResult;
    }
    const result = await deleteUser(userId);
    if (result instanceof Error) {
      error('Company has not been deleted!');
      throw result;
    } else if (meResult.me.userId == userId) {
      await storage.clear();
      setToken(null);
    } else {
      success('Company has been deleted!');
      navigation.pop();
    }
  }

  async function onSaveUser(updatedUser: {
    userId: number;
    email: string;
    username: string;
    active: number;
    companyId: number;
    role: number;
    createDate: string;
    password: string;
    confirmPassword: string;
  }) {
    try {
      if (updatedUser.password.length > 0 && updatedUser.password.length > 0) {
        if (updatedUser.password !== updatedUser.confirmPassword) {
          throw new Error('Passwords do not match!');
        }
        if (updatedUser.password.length < 8) {
          throw new Error('Password must be atleast 8 characters!');
        }
      }
      const result = await updateUser(userId, {
        email: updatedUser.email,
        username: updatedUser.username,
        companyId: updatedUser.companyId,
        createDate: updatedUser.createDate,
        role: +updatedUser.role,
        active: +updatedUser.active,
      });
      if (result instanceof Error) {
        throw result;
      }
      if (updatedUser.password.length > 0 && updatedUser.password.length > 0) {
        const passwordResult = await updatePasswordAdmin(userId, {
          password: updatedUser.password,
        });
        if (passwordResult instanceof Error) {
          throw passwordResult;
        }
      }
      success('Successfully saved!');
    } catch (e) {
      error('Error saving: ' + e);
    }
  }

  async function fetchUser() {
    const result = await getUser(userId);
    if (result instanceof Error) {
      throw result;
    }

    setUser(result.user);
  }

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <SafeAreaView style={{height: Dimensions.get('window').height - 190}}>
      <Header />
      <SubPageTitle name={user?.username ?? ''} />
      <HiveView>
        <WarningModal
          description="Are you sure? This will permentaly delete the user. If there are no remaining administrators the company will be deleted along with user."
          onCancel={() => setShowWarning(false)}
          visible={showWarning}>
          <Button
            type="danger"
            style={{
              width: '100%',
              marginVertical: '1%',
            }}
            onPress={() => handleDelete()}>
            Delete User
          </Button>
        </WarningModal>
        <View>
          <View
            style={{
              display: 'flex',
              alignSelf: 'center',
              width: '95%',
              padding: 12,
            }}>
            {user ? (
              <Formik
                initialValues={{...user, password: '', confirmPassword: ''}}
                onSubmit={values => {
                  onSaveUser(values);
                }}>
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <View style={{display: 'flex'}}>
                    <View style={{marginBottom: 16}}>
                      <View style={{marginVertical: 4}}>
                        <Text style={{fontWeight: '500'}}>Username</Text>
                        <Text
                          style={{
                            borderColor: Colors.Border,
                            borderRadius: 5,
                            borderWidth: 1,
                            backgroundColor: 'white',
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            marginVertical: 4,
                            width: '100%',
                            color: 'grey',
                          }}>
                          {values.username}
                        </Text>
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Email</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          multiline
                          numberOfLines={1}
                          onChangeText={handleChange('email')}
                          onBlur={handleBlur('email')}
                          value={values.email}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Password</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          multiline
                          numberOfLines={1}
                          onChangeText={handleChange('password')}
                          onBlur={handleBlur('password')}
                          value={values.password}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Confirm Password</Text>
                        <Input
                          style={{
                            display: 'flex',
                            width: '100%',
                          }}
                          multiline
                          numberOfLines={1}
                          onChangeText={handleChange('confirmPassword')}
                          onBlur={handleBlur('confirmPassword')}
                          value={values.confirmPassword}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Active</Text>
                        <Select
                          onValueChange={(option: string) =>
                            setFieldValue('active', option)
                          }
                          onBlur={handleBlur('active')}
                          selectedValue={values.active}
                          items={[
                            {label: 'no', value: 0},
                            {label: 'yes', value: 1},
                          ]}
                          placeholder={{}}
                        />
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text>Role</Text>
                        {user.role < 15 ? (
                          <Select
                            onValueChange={(option: string) =>
                              setFieldValue('role', option)
                            }
                            onBlur={handleBlur('role')}
                            selectedValue={values.role}
                            items={[
                              {label: 'Reader', value: 1},
                              {label: 'Contributor', value: 5},
                              {label: 'Administrator', value: 10},
                            ]}
                            placeholder={{}}
                          />
                        ) : (
                          <Text
                            style={{
                              borderColor: Colors.Border,
                              borderRadius: 5,
                              borderWidth: 1,
                              backgroundColor: 'white',
                              paddingHorizontal: 8,
                              paddingVertical: 8,
                              marginVertical: 4,
                              width: '100%',
                              color: 'grey',
                            }}>
                            Super Administrator
                          </Text>
                        )}
                      </View>
                      <View style={{marginVertical: 4}}>
                        <Text style={{fontWeight: '500'}}>Created on</Text>
                        <Text
                          style={{
                            borderColor: Colors.Border,
                            borderRadius: 5,
                            borderWidth: 1,
                            backgroundColor: 'white',
                            paddingHorizontal: 8,
                            paddingVertical: 8,
                            marginVertical: 4,
                            width: '100%',
                            color: 'grey',
                          }}>
                          {moment(values?.createDate).format('LL')}
                        </Text>
                      </View>
                    </View>

                    <Button
                      style={{
                        width: '100%',
                        marginVertical: '0.5%',
                      }}
                      onPress={() => handleSubmit()}>
                      Save User
                    </Button>
                  </View>
                )}
              </Formik>
            ) : (
              <ActivityIndicator />
            )}
          </View>
        </View>
        <TouchableOpacity
          style={{
            display: 'flex',
            width: 'fit-content',
            alignSelf: 'center',
          }}
          onPress={() => setShowWarning(true)}>
          <Text style={{color: 'red', textDecorationLine: 'underline'}}>
            Delete Account
          </Text>
        </TouchableOpacity>
      </HiveView>
    </SafeAreaView>
  );
}
