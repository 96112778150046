import {useNavigation} from '@react-navigation/native';
import React, {useEffect, useState} from 'react';
import {Dimensions, SafeAreaView, View, Text} from 'react-native';
import {
  cancelSubscription,
  getCompany,
  getMe,
  getPaymentMethodPreview,
  getPaymentMethodStatus,
  getUpcomingInvoice,
  updateSubscriptionPaymentMethod,
} from '../../api';
import Header from '../../components/header';
import HiveView from '../../components/hive-view';
import SubPageTitle from '../../components/subpageTitle';
import {Company, Invoice, PaymentMethodPreview, User} from '../../types';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import Loading from '../../components/loading';
import Button from '../../components/button';
const useNotify = require('../../helpers/use-notify').default;

import Icon from '../../components/icons';
import {Colors} from '../../constants';
import Card from '../../components/card';
import moment from 'moment';

export default function PaymentUpdate() {
  const [paymentPreview, setPaymentPreview] = useState<PaymentMethodPreview>();
  const [company, setCompany] = useState<Company>();
  const windowHeight = Dimensions.get('window').height;
  const windowWidth = Dimensions.get('window').width;
  const elements = useElements();
  const stripe = useStripe();
  const navigation = useNavigation<any>();
  const {error, success} = useNotify();

  async function onUpdatePaymentMethod() {
    if (!stripe || !elements) {
      error("We're not yet ready for your payment. Please try again.");
    } else {
      const {paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      });
      if (!paymentMethod) {
        error('We were unable to find your payment method. Please try again.');
      } else {
        const result = await updateSubscriptionPaymentMethod({
          paymentMethodId: paymentMethod.id,
        });
        if (result instanceof Error) {
          throw result;
        } else {
          elements.getElement(CardElement)?.clear();
          success('Payment method successfully updated');
          navigation.pop();
        }
      }
    }
  }

  async function fetchData() {
    const meResult = await getMe();
    if (meResult instanceof Error) {
      throw meResult;
    }

    const companyResult = await getCompany(meResult.me.companyId);

    if (companyResult instanceof Error) {
      throw companyResult;
    }
    setCompany(companyResult.company);
  }

  async function fetchPreview() {
    const previewResult = await getPaymentMethodPreview();
    if (previewResult instanceof Error) {
      throw previewResult;
    }
    setPaymentPreview(previewResult.paymentMethodPreview);
  }

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      fetchData();
      fetchPreview();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <SafeAreaView style={{flex: 1}}>
      <View
        style={{
          height: windowHeight - 140,
        }}>
        <Header />
        <SubPageTitle name="License Management" />
        {company ? (
          <HiveView>
            <View
              style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
              }}>
              <View
                style={{
                  display: 'flex',
                  alignSelf: 'center',
                  justifyContent: 'space-evenly',
                  backgroundColor: 'white',
                  borderColor: 'grey',
                  borderWidth: 2,
                  borderRadius: 10,
                  height: windowHeight * 0.3,
                  width: windowWidth * 0.5,
                  padding: 25,
                }}>
                <View
                  style={{
                    padding: 10,
                    margin: 10,
                    borderWidth: 1,
                    borderRadius: 5,
                  }}>
                  <CardElement />
                </View>
                <Button onPress={() => onUpdatePaymentMethod()}>
                  {paymentPreview
                    ? 'Update Payment Method'
                    : 'Add Payment Method'}
                </Button>
              </View>
            </View>
          </HiveView>
        ) : (
          <Loading />
        )}
      </View>
    </SafeAreaView>
  );
}
