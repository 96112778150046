import React from "react";
import { ActivityIndicator, View } from "react-native";

export default function BigLoading() {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        marginTop: 32,
      }}
    >
      <ActivityIndicator size={48} />
    </View>
  );
}
