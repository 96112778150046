import React from "react";
import { View, ViewProps } from "react-native";
import { Colors } from "../constants";
import composePropsWithDefaultStyles from "../helpers/compose-props-with-default-styles";

interface Props extends ViewProps {}

export default function Card(props: Props) {
  return (
    <View
      {...composePropsWithDefaultStyles(
        {
          borderRadius: 5,
          borderColor: Colors.OffWhite,
          borderWidth: 1,
          shadowColor: Colors.Gray,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.1,
          shadowRadius: 3.84,
          elevation: 5,
        },
        props as any
      )}
    />
  );
}
