import React, {useEffect, useState} from 'react';
import {
  Dimensions,
  SafeAreaView,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import {getMe, updatePassword} from '../../api';
import Button from '../../components/button';
import Header from '../../components/header';
import Input from '../../components/input';
import {User} from '../../types';
import moment from 'moment';
import {Colors} from '../../constants';
import {useRoute} from '@react-navigation/native';
import SubPageTitle from '../../components/subpageTitle';
import HiveView from '../../components/hive-view';
const useNotify = require('../../helpers/use-notify').default;

export default function UpdatePassword() {
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [windowHeight, setWindowHeight] = useState<number>(
    Dimensions.get('window').height,
  );
  const {success, error} = useNotify();
  const route = useRoute<any>();

  const {me} = route.params;

  async function onUpdatePassword() {
    if (me) {
      if (passwords.newPassword === passwords.confirmPassword) {
        const result = await updatePassword(me?.userId, {
          newPassword: passwords.newPassword,
          currentPassword: passwords.currentPassword,
        });
        if (result instanceof Error) {
          error('Password has not been updated!');
          throw result;
        } else {
          success('Password has been updated!');
        }
      }
    }
  }

  return (
    <SafeAreaView style={{height: windowHeight}}>
      <View style={{flex: 1, alignItems: 'center'}}>
        <Header />
        <SubPageTitle name="Settings" name2="Update Password" />
        <View
          style={{
            height: windowHeight - 140,
            width: '100%',
          }}>
          {me ? (
            <HiveView>
              <View
                style={{
                  height: windowHeight - 140,
                  paddingVertical: 16,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <View
                  style={{
                    width: '90%',
                  }}>
                  <View style={{marginVertical: 4}}>
                    <Text style={{fontWeight: '500'}}>Username</Text>
                    <Text
                      style={{
                        borderColor: Colors.Border,
                        borderRadius: 5,
                        borderWidth: 1,
                        backgroundColor: '#d3d3d3',
                        paddingHorizontal: 8,
                        paddingVertical: 8,
                        marginVertical: 4,
                        fontSize: 18,
                      }}>
                      {me?.username}
                    </Text>
                  </View>
                  <View style={{marginVertical: 4}}>
                    <Text style={{fontWeight: '500'}}>Current Password*</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      textContentType="password"
                      secureTextEntry
                      value={passwords.currentPassword}
                      onChange={v =>
                        setPasswords({
                          ...passwords,
                          currentPassword: v.nativeEvent.text,
                        })
                      }
                    />
                  </View>
                  <View style={{marginVertical: 4}}>
                    <Text style={{fontWeight: '500'}}>New Password*</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      textContentType="password"
                      secureTextEntry
                      value={passwords.newPassword}
                      onChange={v =>
                        setPasswords({
                          ...passwords,
                          newPassword: v.nativeEvent.text,
                        })
                      }
                    />
                  </View>
                  <View style={{marginVertical: 4}}>
                    <Text style={{fontWeight: '500'}}>Confirm Password*</Text>
                    <Input
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                      textContentType="password"
                      secureTextEntry
                      value={passwords.confirmPassword}
                      onChange={v =>
                        setPasswords({
                          ...passwords,
                          confirmPassword: v.nativeEvent.text,
                        })
                      }
                    />
                  </View>
                </View>
                <Button
                  style={{width: '90%'}}
                  onPress={() => onUpdatePassword()}>
                  Save Profile
                </Button>
              </View>
            </HiveView>
          ) : (
            <ActivityIndicator />
          )}
        </View>
      </View>
    </SafeAreaView>
  );
}
